import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import uuidv1 from 'uuid/v1';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import Help, {
  HelpCarousel,
  HelpCarouselWindow,
  HelpCarouselItem,
  Title,
  SubTitle,
  Target,
  TargetWrap,
  Layers,
  LayersWrap,
  GameWrap,
  Options,
  OptionsWrap,
  WarningWrap,
  Info,
  OverviewTitle,
  OverviewInfo,
  OverviewWrap,
  Button,
  PipWrap,
  Pip,
} from './HelpOverlay.styled';
import CloseHelp from '../../1-atoms/CloseHelp/CloseHelp';
import {
  transitionDuration,
  convertType,
  currentType,
  terms,
  doop,
  strapline,
  helpTransitions,
} from '../../../utils/constants/constants';
import GoalBlock from '../../1-atoms/GoalBlock/GoalBlock';
import GameLayers from '../../2-molecules/GameLayers/GameLayers';
import { convertMDArrayToMDObj } from '../../../utils/helpers/convertMDArrayToMDObj';
import GameBlockOptions from '../../2-molecules/GameBlockOptions/GameBlockOptions';
import { GameDirection, GameLayersStyled } from '../Game/Game.styled';
import IconUp from '../../1-atoms/icons/IconUp/IconUp';
import IconTick from '../../1-atoms/icons/IconTick/IconTick';
import { showHelpOverlay } from '../../../state/actions/showHelpOverlay';
import { changeHelpPosition } from '../../../state/actions/changeHelpPosition';
import { changeHelpStage } from '../../../state/actions/changeHelpStage';
import ExampleAnimation from '../../1-atoms/ExampleAnimation/ExampleAnimation';
import {
  gameData,
  targetData,
  gameData2,
  target2Data,
  gameDataMeanies2,
} from './data';
import EndGameMeanies from '../../2-molecules/EndGameMeanies/EndGameMeanies';

const game1Blocks = convertMDArrayToMDObj(gameData, convertType.normal);
const game1Layer = convertMDArrayToMDObj(gameData[0], convertType.shallow);
const game1Layer2 = convertMDArrayToMDObj(gameData[1], convertType.shallow);
const game2Blocks = convertMDArrayToMDObj(gameData2, convertType.normal);
const game2Layer = convertMDArrayToMDObj(gameData2[0], convertType.shallow);
const game2Layer2 = convertMDArrayToMDObj(gameData2[1], convertType.shallow);
const game2LayerMeanies = convertMDArrayToMDObj(
  gameDataMeanies2[0],
  convertType.shallow
);

const mapDispatchToProps = dispatch => ({
  showHelpOverlay: obj => dispatch(showHelpOverlay(obj)),
  changeHelpPosition: str => dispatch(changeHelpPosition(str)),
  changeHelpStage: num => dispatch(changeHelpStage(num)),
});

const mapStateToProps = state => ({
  helpOpen: state.app.help.open,
  helpPosition: state.app.help.info,
  helpStage: state.app.help.stage,
  helpStageTotal: state.app.help.stageTotal,
  level: state.saved.current,
});

const HelpOverlay = ({
  showHelpOverlay,
  changeHelpPosition,
  changeHelpStage,
  helpOpen,
  helpPosition,
  helpStage,
  helpStageTotal,
  level,
}) => {
  const [showAddLayer, setShowAddLayer] = useState(false);
  const [showRotation, setShowRotation] = useState(false);
  const [addLayer2, setAddLayer2] = useState(false);

  const helpStages = new Array(helpStageTotal)
    .fill(0)
    .map(() => ({ key: uuidv1() }));

  useEffect(() => {
    setShowAddLayer(helpPosition === currentType.layers2);
    setShowRotation(helpPosition === currentType.layers3);
    if (helpPosition === currentType.layers2) setAddLayer2(true);
    if (!helpOpen) setAddLayer2(false);
  }, [helpPosition, helpStage, helpOpen]);

  const stage1 = helpOpen && helpStage === 1;
  const stage2 = helpOpen && helpStage === 2;

  const overview = helpPosition === currentType.overview && stage1;
  const options = helpPosition === currentType.options && stage1;
  const layers = helpPosition === currentType.layers && stage1;
  const layers2 = helpPosition === currentType.layers2 && stage1;
  const layers3 = helpPosition === currentType.layers3 && stage1;
  const target = helpPosition === currentType.target && stage1;
  const target2 = helpPosition === currentType.target2 && stage1;

  const overview2 = helpPosition === currentType.overview2 && stage2;
  const meanies = helpPosition === currentType.meanies && stage2;
  const layers4 = helpPosition === currentType.layers4 && stage2;
  const layers5 = helpPosition === currentType.layers5 && stage2;
  const layers6 = helpPosition === currentType.layers6 && stage2;
  const options2 = helpPosition === currentType.options2 && stage2;

  const handleClick = (type, curLevel) => {
    if (type === currentType.overview || type === currentType.overview2) {
      showHelpOverlay({ open: false, level: curLevel });
      changeHelpPosition(type);
    } else {
      changeHelpPosition(type);
    }
  };

  return (
    <div
      className={`${showAddLayer ? helpTransitions.add : ''} ${
        showRotation ? helpTransitions.rotate : ''
      }`}
    >
      <CSSTransition
        classNames="help"
        in={helpOpen}
        timeout={transitionDuration}
      >
        <Help aria-hidden={!helpOpen}>
          <CloseHelp title="Close Help" />
          <CSSTransition
            classNames="title"
            in={helpOpen}
            timeout={transitionDuration}
          >
            <Title>
              <span className="how">How to play</span>{' '}
              <span className="title">{doop}</span>
            </Title>
          </CSSTransition>
          <CSSTransition
            classNames="stage-title"
            in={stage1}
            timeout={transitionDuration * 4}
          >
            <SubTitle aria-hidden={!stage1}>
              <span className="text">{terms.tutorial}</span>{' '}
              <span className="number">{helpStage}</span>
            </SubTitle>
          </CSSTransition>
          <CSSTransition
            classNames="stage-title"
            in={stage2}
            timeout={transitionDuration * 4}
          >
            <SubTitle aria-hidden={!stage2}>
              <span className="text">{terms.tutorial}</span>{' '}
              <span className="number">{helpStage}</span>
            </SubTitle>
          </CSSTransition>
          <PipWrap>
            {helpStages.map((pip, index) => (
              <Pip
                key={pip.key}
                type="button"
                active={index === helpStage - 1}
                disabled={index === helpStage - 1}
                onClick={() => changeHelpStage(index + 1)}
                title={`Help Stage ${index + 1}`}
              />
            ))}
          </PipWrap>
          <HelpCarouselWindow>
            <HelpCarousel helpTotal={helpStageTotal} helpStage={helpStage}>
              <HelpCarouselItem active={helpStage === 1}>
                <CSSTransition
                  classNames="overview"
                  in={overview}
                  timeout={transitionDuration * 6}
                >
                  <OverviewWrap>
                    <CSSTransition
                      classNames="overview-info"
                      in={overview}
                      timeout={0}
                    >
                      <OverviewInfo data-info aria-hidden={!overview}>
                        <OverviewTitle>
                          {terms.tutorial} <span>{helpStage}</span>
                        </OverviewTitle>
                        <ExampleAnimation small help color="dark" />
                        <p>
                          To win at {doop}, you have to arrange your{' '}
                          <strong>{terms.stack}</strong> to match the{' '}
                          <strong>{terms.goal}</strong>
                        </p>
                        <Button
                          data-help-ok
                          onClick={() => handleClick(currentType.target, level)}
                          title={terms.understood}
                          tabIndex="0"
                        >
                          <IconTick
                            light
                            sizeWH={26}
                            title={terms.understood}
                          />
                        </Button>
                      </OverviewInfo>
                    </CSSTransition>
                  </OverviewWrap>
                </CSSTransition>
                <CSSTransition
                  classNames="target"
                  in={helpOpen && stage1}
                  timeout={0}
                >
                  <TargetWrap>
                    <Target
                      className={
                        target && (options || layers || layers2 || layers3)
                          ? 'active-target lower'
                          : target2
                          ? 'active-target2'
                          : ''
                      }
                    >
                      <CSSTransition
                        classNames="target-info"
                        in={target}
                        timeout={transitionDuration}
                      >
                        <Info data-info arrow="bottom" aria-hidden={!target}>
                          <p>
                            This is your <strong>{terms.goal}</strong>
                          </p>
                          <Button
                            data-help-ok
                            onClick={() =>
                              handleClick(currentType.layers, level)
                            }
                            title={terms.understood}
                            tabIndex="0"
                          >
                            <IconTick
                              light
                              sizeWH={26}
                              title={terms.understood}
                            />
                          </Button>
                        </Info>
                      </CSSTransition>
                      <CSSTransition
                        classNames="target-info2"
                        in={target2}
                        timeout={transitionDuration}
                      >
                        <Info data-info arrow="bottom" aria-hidden={!target2}>
                          <p>
                            <strong>To win</strong>, all you do is make your{' '}
                            <strong>{terms.stack}</strong> match the{' '}
                            <strong>{terms.goal}</strong>
                            <br />- good luck!
                          </p>
                          <Button
                            data-help-ok
                            onClick={() =>
                              handleClick(currentType.overview, level)
                            }
                            title={terms.understood}
                            tabIndex="0"
                          >
                            <IconTick
                              light
                              sizeWH={26}
                              title={terms.understood}
                            />
                          </Button>
                        </Info>
                      </CSSTransition>
                      <GoalBlock dark successOrder={targetData} />
                    </Target>
                  </TargetWrap>
                </CSSTransition>
                <LayersWrap>
                  <CSSTransition
                    classNames="game"
                    in={helpOpen && stage1}
                    timeout={transitionDuration}
                  >
                    <GameWrap>
                      <Layers
                        className={
                          layers
                            ? 'active-layers'
                            : layers2
                            ? 'active-layers2'
                            : layers3
                            ? 'active-layers3'
                            : ''
                        }
                      >
                        <CSSTransition
                          classNames="layers-info"
                          in={layers}
                          timeout={transitionDuration * 2}
                        >
                          <Info
                            className="layers-info"
                            data-info
                            arrow="bottom"
                            aria-hidden={!layers}
                          >
                            <p>
                              This is your <strong>{terms.stack}</strong>
                            </p>
                            <Button
                              data-help-ok
                              onClick={() =>
                                handleClick(currentType.options, level)
                              }
                              title={terms.understood}
                              tabIndex="0"
                            >
                              <IconTick
                                light
                                sizeWH={26}
                                title={terms.understood}
                              />
                            </Button>
                          </Info>
                        </CSSTransition>
                        <CSSTransition
                          classNames="layers-info2"
                          in={layers2}
                          timeout={transitionDuration * 2}
                        >
                          <Info data-info arrow="bottom" aria-hidden={!layers2}>
                            <p>
                              When you tap a <strong>{terms.option}</strong>, it
                              gets added to top of your{' '}
                              <strong>{terms.stack}</strong>
                            </p>
                            <Button
                              data-help-ok
                              tabIndex="0"
                              title={terms.understood}
                              onClick={() =>
                                handleClick(currentType.layers3, level)
                              }
                            >
                              <IconTick
                                light
                                sizeWH={26}
                                title={terms.understood}
                              />
                            </Button>
                          </Info>
                        </CSSTransition>
                        <CSSTransition
                          classNames="layers-info3"
                          in={layers3}
                          timeout={transitionDuration * 2}
                        >
                          <Info data-info arrow="bottom" aria-hidden={!layers3}>
                            <p>
                              From here, you can tap to <strong>rotate</strong>{' '}
                              the top layer of your{' '}
                              <strong>{terms.stack}</strong>, or tap the{' '}
                              <strong>X</strong> to remove it
                            </p>
                            <Button
                              data-help-ok
                              onClick={() =>
                                handleClick(currentType.target2, level)
                              }
                              tabIndex="0"
                              title={terms.understood}
                            >
                              <IconTick
                                light
                                sizeWH={26}
                                title={terms.understood}
                              />
                            </Button>
                          </Info>
                        </CSSTransition>
                        <GameDirection>
                          <IconUp title={strapline} position="top" dark />
                          <GameLayersStyled>
                            <GameLayers
                              win={false}
                              fail={false}
                              buttonsDisabled
                              layersCount={addLayer2 ? 1 : 0}
                              gameLayers={
                                addLayer2
                                  ? [game1Layer, game1Layer2]
                                  : [game1Layer]
                              }
                              gameOptionsInUse={[1, 2]}
                              removeLayer={() => null}
                              rotateLayer={() => null}
                              rotateBlocksActive={{ main: 1, option: 1 }}
                            />
                          </GameLayersStyled>
                          <IconUp title={strapline} position="bottom" dark />
                        </GameDirection>
                      </Layers>
                    </GameWrap>
                  </CSSTransition>
                </LayersWrap>
                <CSSTransition
                  classNames="options"
                  in={helpOpen && stage1}
                  timeout={transitionDuration}
                >
                  <OptionsWrap>
                    <Options
                      className={
                        options
                          ? 'options-active'
                          : options2
                          ? 'options2-active'
                          : ''
                      }
                    >
                      <CSSTransition
                        classNames="options-info"
                        in={options}
                        timeout={transitionDuration * 2}
                      >
                        <Info data-info aria-hidden={!options}>
                          <p>
                            These are your <strong>{terms.options}</strong>
                            <br />
                            (The first {terms.option.toLowerCase()} is always
                            locked)
                          </p>
                          <Button
                            data-help-ok
                            onClick={() =>
                              handleClick(currentType.layers2, level)
                            }
                            tabIndex="0"
                            title={terms.understood}
                          >
                            <IconTick
                              light
                              sizeWH={26}
                              title={terms.understood}
                            />
                          </Button>
                        </Info>
                      </CSSTransition>
                      <GameBlockOptions
                        gameBlocks={game1Blocks}
                        addLayerToStack={() => null}
                        buttonsDisabled
                        win={false}
                        fail={false}
                        removeLayer={() => null}
                        gameOptionsInUse={[0]}
                        rotateBlocksActive={{ main: 0, option: 0 }}
                      />
                    </Options>
                  </OptionsWrap>
                </CSSTransition>
              </HelpCarouselItem>
              <HelpCarouselItem active={helpStage === 2}>
                <CSSTransition
                  classNames="overview2"
                  in={overview2}
                  timeout={transitionDuration * 6}
                >
                  <OverviewWrap>
                    <CSSTransition
                      classNames="overview-info2"
                      in={overview2}
                      timeout={0}
                    >
                      <OverviewInfo data-info aria-hidden={!overview2}>
                        <OverviewTitle>
                          {terms.tutorial} <span>{helpStage}</span>
                        </OverviewTitle>
                        <EndGameMeanies help />
                        <p>
                          Nice! You've got this far, but now it'll get a bit{' '}
                          <strong>trickier...</strong>
                        </p>
                        <Button
                          data-help-ok
                          onClick={() =>
                            handleClick(currentType.layers4, level)
                          }
                          title={terms.understood}
                          tabIndex="0"
                        >
                          <IconTick
                            light
                            sizeWH={26}
                            title={terms.understood}
                          />
                        </Button>
                      </OverviewInfo>
                    </CSSTransition>
                  </OverviewWrap>
                </CSSTransition>
                <CSSTransition
                  classNames="target"
                  in={helpOpen && stage2}
                  timeout={0}
                >
                  <TargetWrap>
                    <Target
                      className={
                        target &&
                        (overview2 ||
                          options2 ||
                          layers4 ||
                          layers5 ||
                          layers6 ||
                          meanies)
                          ? 'active-target lower'
                          : target2
                          ? 'active-target2'
                          : ''
                      }
                    >
                      <GoalBlock dark successOrder={target2Data} />
                    </Target>
                  </TargetWrap>
                </CSSTransition>
                <LayersWrap>
                  <CSSTransition
                    classNames="warning"
                    in={meanies || options2 || layers5 || layers6}
                    timeout={transitionDuration}
                  >
                    <WarningWrap
                      className={
                        options2
                          ? 'warning-enter-done lower'
                          : layers5 || layers6 || meanies
                          ? 'warning-enter-done'
                          : ''
                      }
                    >
                      <Layers
                        overlay
                        className={
                          meanies || options2 || layers5 || layers6
                            ? 'show active'
                            : 'hide'
                        }
                      >
                        <CSSTransition
                          classNames="meanies-info"
                          in={meanies}
                          timeout={transitionDuration}
                        >
                          <Info data-info arrow="bottom" aria-hidden={!meanies}>
                            <p>
                              In fact, if you make a line of{' '}
                              <strong>{terms.meanies}</strong>, then it&rsquo;s{' '}
                              <strong>game over!</strong>
                            </p>
                            <Button
                              data-help-ok
                              onClick={() =>
                                handleClick(currentType.options2, level)
                              }
                              title={terms.understood}
                              tabIndex="0"
                            >
                              <IconTick
                                light
                                sizeWH={26}
                                title={terms.understood}
                              />
                            </Button>
                          </Info>
                        </CSSTransition>
                        <CSSTransition
                          classNames="layers-info5"
                          in={layers5}
                          timeout={transitionDuration * 2}
                        >
                          <Info data-info arrow="bottom" aria-hidden={!layers5}>
                            <p>
                              They're called <strong>{terms.meanies}</strong>.
                              They're <strong>red</strong>, they have a white
                              dot, and they're <strong>bad for you</strong>...
                            </p>
                            <Button
                              data-help-ok
                              onClick={() =>
                                handleClick(currentType.meanies, level)
                              }
                              tabIndex="0"
                              title={terms.understood}
                            >
                              <IconTick
                                light
                                sizeWH={26}
                                title={terms.understood}
                              />
                            </Button>
                          </Info>
                        </CSSTransition>
                        <CSSTransition
                          classNames="layers-info6"
                          in={layers6}
                          timeout={transitionDuration * 2}
                        >
                          <Info data-info arrow="bottom" aria-hidden={!layers6}>
                            <p>
                              As before, keep matching your{' '}
                              <strong>{terms.stack}</strong> with the{' '}
                              <strong>{terms.goal}</strong>, but watch out for a
                              line of <strong>{terms.meanies}</strong>!
                            </p>
                            <Button
                              data-help-ok
                              onClick={() =>
                                handleClick(currentType.overview2, level)
                              }
                              tabIndex="0"
                              title={terms.understood}
                            >
                              <IconTick
                                light
                                sizeWH={26}
                                title={terms.understood}
                              />
                            </Button>
                          </Info>
                        </CSSTransition>

                        <GameDirection>
                          <IconUp title={strapline} position="top" dark />
                          <GameLayersStyled>
                            <GameLayers
                              win={false}
                              fail
                              buttonsDisabled
                              layersCount={addLayer2 ? 1 : 0}
                              gameLayers={[game2LayerMeanies]}
                              gameOptionsInUse={[0]}
                              removeLayer={() => null}
                              rotateLayer={() => null}
                              rotateBlocksActive={{ main: 0, option: 0 }}
                            />
                          </GameLayersStyled>
                          <IconUp title={strapline} position="bottom" dark />
                        </GameDirection>
                      </Layers>
                    </WarningWrap>
                  </CSSTransition>
                  <CSSTransition
                    classNames="game"
                    in={helpOpen && stage2}
                    timeout={transitionDuration}
                  >
                    <GameWrap lower={meanies}>
                      <Layers className={layers4 ? 'active-layers4' : ''}>
                        <CSSTransition
                          classNames="layers-info4"
                          in={layers4}
                          timeout={transitionDuration * 2}
                        >
                          <Info data-info arrow="bottom" aria-hidden={!layers4}>
                            <p>You'll start to notice some new circles...</p>
                            <Button
                              data-help-ok
                              onClick={() =>
                                handleClick(currentType.layers5, level)
                              }
                              tabIndex="0"
                              title={terms.understood}
                            >
                              <IconTick
                                light
                                sizeWH={26}
                                title={terms.understood}
                              />
                            </Button>
                          </Info>
                        </CSSTransition>

                        <GameDirection>
                          <IconUp title={strapline} position="top" dark />
                          <GameLayersStyled
                            hide={meanies || layers5 || layers6}
                          >
                            <GameLayers
                              win={false}
                              fail={false}
                              buttonsDisabled
                              layersCount={addLayer2 ? 1 : 0}
                              gameLayers={
                                addLayer2
                                  ? [game2Layer, game2Layer2]
                                  : [game2Layer]
                              }
                              gameOptionsInUse={[1, 2]}
                              removeLayer={() => null}
                              rotateLayer={() => null}
                              rotateBlocksActive={{ main: 1, option: 1 }}
                            />
                          </GameLayersStyled>
                          <IconUp title={strapline} position="bottom" dark />
                        </GameDirection>
                      </Layers>
                    </GameWrap>
                  </CSSTransition>
                </LayersWrap>
                <CSSTransition
                  classNames="options"
                  in={helpOpen && stage2}
                  timeout={transitionDuration}
                >
                  <OptionsWrap>
                    <Options
                      className={
                        options
                          ? 'options-active'
                          : options2
                          ? 'options2-active'
                          : ''
                      }
                    >
                      <CSSTransition
                        classNames="options-info"
                        in={options}
                        timeout={transitionDuration * 2}
                      >
                        <Info data-info aria-hidden={!options}>
                          <p>
                            These are your <strong>{terms.options}</strong>
                            <br />
                            (The first {terms.option.toLowerCase()} is always
                            locked)
                          </p>
                          <Button
                            data-help-ok
                            onClick={() =>
                              handleClick(currentType.layers2, level)
                            }
                            tabIndex="0"
                            title={terms.understood}
                          >
                            <IconTick
                              light
                              sizeWH={26}
                              title={terms.understood}
                            />
                          </Button>
                        </Info>
                      </CSSTransition>
                      <CSSTransition
                        classNames="options-info2"
                        in={options2}
                        timeout={transitionDuration * 2}
                      >
                        <Info data-info aria-hidden={!options2}>
                          <p>
                            But to help you avoid a line of{' '}
                            <strong>{terms.meanies}</strong>, you can{' '}
                            <strong>rotate</strong> or <strong>remove</strong>{' '}
                            individual <strong>{terms.options}</strong> here
                          </p>
                          <Button
                            data-help-ok
                            onClick={() =>
                              handleClick(currentType.layers6, level)
                            }
                            tabIndex="0"
                            title={terms.understood}
                          >
                            <IconTick
                              light
                              sizeWH={26}
                              title={terms.understood}
                            />
                          </Button>
                        </Info>
                      </CSSTransition>
                      <GameBlockOptions
                        gameBlocks={game2Blocks}
                        addLayerToStack={() => null}
                        buttonsDisabled
                        win={false}
                        fail={false}
                        removeLayer={() => null}
                        gameOptionsInUse={[0]}
                        rotateBlocksActive={{ main: 0, option: 0 }}
                      />
                    </Options>
                  </OptionsWrap>
                </CSSTransition>
              </HelpCarouselItem>
            </HelpCarousel>
          </HelpCarouselWindow>
        </Help>
      </CSSTransition>
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HelpOverlay);

HelpOverlay.propTypes = {
  helpStage: PropTypes.number.isRequired,
  helpStageTotal: PropTypes.number.isRequired,
  helpOpen: PropTypes.bool.isRequired,
  helpPosition: PropTypes.string.isRequired,
  showHelpOverlay: PropTypes.func.isRequired,
  changeHelpStage: PropTypes.func.isRequired,
};
