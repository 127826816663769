import React, { Component } from 'react';
import styled from 'styled-components';
import PageTitle from '../../1-atoms/PageTitle/PageTitle';
import FullPageCentered from '../FullPageCentered/FullPageCentered';
import IconUnhappy from '../../1-atoms/icons/IconUnhappy/IconUnhappy';
import ErrorBoundaryStyled from './ErrorBoundary.styled';

const ResetButton = styled.button`
  background: ${props => props.theme.colors.copyAlt};
  border-radius: 5px;
  border: 0;
  color: ${props => props.theme.colors.copy};
  cursor: pointer;
  display: block;
  font-family: ${props => props.theme.fonts.primary};
  font-size: calc(${props => props.theme.fonts.baseSize} - 6px);
  font-weight: 600;
  margin: 10px 5px;
  outline: 0;
  padding: 8px 13px;
  text-transform: uppercase;

  &:hover,
  &:focus {
    background: ${props => props.theme.colors.error};
    color: ${props => props.theme.colors.copy};
  }
`;

const Small = styled.small`
  font-size: calc(${props => props.theme.fonts.baseSize} - 4px);
  line-height: 1;
  position: relative;
  margin-top: 40px;

  &::before {
    border-top: 2px dotted ${props => props.theme.colors.copyAlt};
    content: '';
    left: 50%;
    position: absolute;
    top: -30px;
    transform: translateX(-50%);
    width: 50px;
  }
`;

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  resetData = () => {
    localStorage.clear();
  };

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    console.warn(error, info);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      // You can render any custom fallback UI
      return (
        <ErrorBoundaryStyled>
          <FullPageCentered>
            <IconUnhappy title="Uh oh." />
            <PageTitle large type="black" title="Something went wrong" />
            <p>Oh god, oh god, we're all doing to die.</p>

            <Small>
              If all else fails, please
              <ResetButton onClick={() => this.resetData()}>
                Reset Saved Data
              </ResetButton>
              and refresh your page.
            </Small>
          </FullPageCentered>
        </ErrorBoundaryStyled>
      );
    }

    return children;
  }
}
