import {
  transitionDuration,
  rotationDuration,
  fadeDuration,
} from '../utils/constants/constants';

const theme = {
  colors: {
    bg: '#8e79f7',
    brand: '#ff9e1b',
    copy: '#fff',
    copyHover: '#E8D468',
    copyAlt: '#444',
    copyLight: '#666',
    icon: '#ffed38',
    error: '#ff659b',
    grey: '#ddd',
    disabled: '#ccc',
    phase1: '#555',
    phase2: '#ff9e1b',
    phase3: '#ff659b',
    purpleAA: '#7256ff',
  },
  fonts: {
    primary: '"Nunito", sans-serif',
    baseSize: '16px',
  },
  links: {
    default: '#ffed38',
    active: '#444',
  },
  burgerNav: {
    svg: '#ffed38',
    dots: '3px',
  },
  inGameStats: {
    bg: '#fff',
    icon: '#8e79f7',
    text: '#8e79f7',
  },
  buttons: {
    borderRadius: '50px',
    boxShadow: '4px 4px 0 rgba(0,0,0,0.1)',
    boxShadowHover: '6px 6px 0 rgba(0,0,0,0.1)',
    boxShadowActive: '1px 1px 2px rgba(0,0,0,0.1)',
    disabled: {
      bg: '#ccc',
      icon: '#8a8a8a',
    },
  },
  endGameOptions: {
    height: '45px',
    button: '#ff659b',
    buttonText: '#ffed38',
    hover: '#ffed38',
  },
  introPanel: {
    bg: '#52ebb3',
    border: '#fff',
    color: '#fff',
    logo: {
      bg: '#ffed38',
      color: '#444',
    },
  },
  helpOverlay: {
    bg: '#ffed38',
    info: {
      borderRadius: '20px',
      boxShadow: '5px 5px 0 rgba(0, 0, 0, 0.1)',
      boxShadowLG: '10px 10px 0 rgba(0, 0, 0, 0.1)',
      bg: '#fff',
      copy: '#444',
    },
    button: {
      bg: '#8e79f7',
      icon: '#fff',
    },
    color: '#444',
  },
  footer: {
    height: '40px',
    heightCalc: 40,
  },
  header: {
    bg: 'transparent',
    height: '65px',
    heightCalc: 65,
    logo: {
      bg: '#ffed38',
      color: '#444',
      border: '#444',
    },
    subNav: {
      bg: '#fff',
      color: '#444',
      active: '#D3DDE0',
    },
  },
  icons: {
    heart: '#444',
    memorised: '#DDED85',
    ok: '#52ebb3',
    disabled: '#999',
    arrow: {
      default: '#ffed38',
      hover: '#8e79f7',
      route: '#ccc',
    },
  },
  gameWin: {
    bg: '#fff',
    border: '#ffed38',
    boxShadow: '5px 5px rgba(0,0,0,0.1)',
    star: '#ff9e1b',
  },
  game: {
    bg: '#fff',
    bgBoxShadow: '0 0 0 calc(20px + 3vmin) rgba(255,255,255,0.1)',
    header: '#8e79f7',
    buttonBg: {
      default: '#ffed38',
      icon: '#8e79f7',
      hover: '#ff9e1b',
      hoverIcon: '#fff',
    },
    barHeight: '50px',
    barHeightCalc: 50,
    padding: '15px',
    moveUp: '20px',
    remove: {
      bg: '#8e79f7',
      bgHoverFill: '#ffed38',
      size: '26px',
      border: '5px solid #fff',
    },
    block: {
      borderRadius: '100%',
      margin: {
        thumb: '3px',
        goal: '3px',
        main: '6px',
      },
      marginLG: {
        thumb: '4px',
        goal: '4px',
        main: '7px',
      },
      marginCalc: 3,
      size: {
        thumb: '7px',
        goal: '8px',
        main: '27px',
      },
      sizeMD: {
        thumb: '8px',
        goal: '10px',
        main: '30px',
      },
      sizeLG: {
        thumb: '10px',
        goal: '11px',
        main: '35px',
      },
      color: {
        bg: '#806dde',
        c0: '#ff9e1b',
        c1: '#80cdff',
        c2: '#ff83f9',
        c3: '#6149b7',
        c4: '#ff659b',
        c5: '#52ebb3',
        c6: '#ff9e1b',
        demoEmpty: '#e6d41f',
        trans: 'transparent',
        dot: '#fff',
        empty: '#e4e4e4',
        hover: '#666',
        success: '#ffed38',
      },
    },
  },
  blockOption: {
    inUse: 'rgba(0,0,0,0.05)',
    inUseHover: 'rgba(0,0,0,0.1)',
    bg: 'rgba(0,0,0,0)',
  },
  transition: {
    duration: `${transitionDuration / 1000}s`,
    rotationDuration: `${rotationDuration / 1000}s`,
    fadeDuration: `${fadeDuration / 1000}s`,
    easeOut: 'cubic-bezier(0.165, 0.84, 0.44, 1)',
    cubicBez: 'cubic-bezier(0.175, 0.885, 0.32, 1.275)',
  },
  breakpoints: {
    xs: '321px',
    xs2: '425px',
    sm: '480px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
    xxl: '1400px',
    heightMin: '900px',
    height: {
      sm: '570px',
      md: '668px',
      md2: '700px',
      lg: '800px',
    },
  },
  zIndex: {
    fixed: 100,
    overlay: 101,
  },
};

export default theme;
