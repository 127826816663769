import React from 'react';
import PropTypes from 'prop-types';
import IconUpStyled from './IconUp.styled';

const IconUp = props => (
  <IconUpStyled width={20} height={20} viewBox="0 0 25 25" {...props}>
    <title>{props.title}</title>
    <path d="M0 16.67l2.829 2.83 9.175-9.339 9.167 9.339 2.829-2.83-11.996-12.17z" />
  </IconUpStyled>
);

export default IconUp;

IconUp.propTypes = {
  title: PropTypes.string.isRequired,
  position: PropTypes.string,
};

IconUp.defaultProps = {
  position: 'top',
};
