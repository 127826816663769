import { css } from 'styled-components';

const slantDeg = '-5deg';

export const slant = css`
  text-transform: uppercase;
  transform: skew(${slantDeg}) rotate(${slantDeg});
`;

export const slantNeutral = css`
  text-transform: uppercase;
  transform: skew(${slantDeg}) rotate(${slantDeg}) translate(0, 0);
`;

export const slantOffsetY = css`
  text-transform: uppercase;
  transform: skew(${slantDeg}) rotate(${slantDeg}) translate(0, 20px);
`;

export const slantOffset = css`
  text-transform: uppercase;
  transform: skew(${slantDeg}) rotate(${slantDeg}) translate(-40%, -40%);
`;

export const slantCentered = css`
  text-transform: uppercase;
  transform: skew(${slantDeg}) rotate(${slantDeg}) translate(-50%, -55%);
`;

export const hoverBefore = css`
  &::before {
    background: ${props => props.theme.colors.copy};
    border-radius: 100%;
    content: '';
    height: 30px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: scale(0) translate(-50%, -50%);
    transition: transform ${props => props.theme.transition.duration}
      ${props => props.theme.transition.cubicBez};
    transform-origin: 0 0;
    width: 30px;
    z-index: -1;
  }

  &:hover {
    svg {
      fill: ${props => props.theme.colors.bg};
    }

    &::before {
      transform: scale(1) translate(-50%, -50%);
    }
  }
`;
