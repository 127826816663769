import styled, { css } from 'styled-components';
import { slantOffsetY, slantNeutral } from '../../../utils/helpers/shared';
import bg from '../../../utils/svgs/bg2.svg';
import {
  rotate90Staggered,
  layerInOut,
} from '../../../utils/helpers/animations';

// CSS HELPERS

const infoOffset = 10;
const infoDefault = css`
  display: none;
  transition-delay: ${props => props.theme.transition.duration};
`;

const infoEnter = css`
  display: flex;
  opacity: 0;
  top: -${infoOffset}px;
  transform: translate(-50%, -120%);
  z-index: -1;
`;

const infoEntered = css`
  align-items: center;
  display: flex;
  justify-content: center;
  opacity: 1;
  transform: translate(-50%, -100%);
  z-index: ${props => props.theme.zIndex.overlay + 2};
`;

const infoExit = css`
  align-items: center;
  display: flex;
  justify-content: center;
  opacity: 0;
  transform: translate(-50%, -120%);
  z-index: ${props => props.theme.zIndex.overlay + 2};
`;

const sectionDefault = css`
  opacity: 0;
  transform: scale(0);
  transition: transform ${props => props.theme.transition.duration}
      ${props => props.theme.transition.cubicBez},
    opacity ${props => props.theme.transition.duration}
      ${props => props.theme.transition.cubicBez},
    z-index ${props => props.theme.transition.duration}
      ${props => props.theme.transition.cubicBez};
  transition-delay: 0.6s;
`;

const sectionDefaultEase = css`
  opacity: 0;
  transform: scale(1.2);
  transition: transform ${props => props.theme.transition.duration}
      ${props => props.theme.transition.easeOut},
    opacity ${props => props.theme.transition.duration}
      ${props => props.theme.transition.easeOut},
    z-index ${props => props.theme.transition.duration}
      ${props => props.theme.transition.easeOut};
  transition-delay: 0.6s;
`;

export const TargetWrap = styled.section`
  ${sectionDefault};

  &.target-enter {
    opacity: 0;
    transform: scale(0);
    transition-delay: 0.3s;
  }

  &.target-enter-done {
    opacity: 1;
    transform: scale(1);
    z-index: ${props => props.theme.zIndex.overlay + 2};

    &.lower {
      z-index: -1;
    }
  }

  [data-help-target] {
    z-index: ${props => (props.lower ? -1 : 1)};
  }
`;

export const Target = styled.section`
  display: inline-block;
  margin: 0 10px;
  opacity: 1;
  padding: 5px;
  position: relative;
  z-index: -1;
  top: 0;

  &.active {
    opacity: 1;
    z-index: 100;
  }

  [data-info] {
    ${infoDefault};
    top: calc(${props => props.theme.game.moveUp} - ${infoOffset * 5}px);

    &.target-info-enter,
    &.target-info2-enter {
      ${infoEnter};
    }
    &.target-info-enter-done,
    &.target-info-exit,
    &.target-info2-enter-done,
    &.target-info2-exit {
      ${infoEntered};
    }

    &.target-info-exit-active,
    &.target-info2-exit-active {
      ${infoExit};
    }
  }
`;

export const LayersWrap = styled.section`
  position: relative;
`;

export const Layers = styled(Target)`
  position: ${props => (props.overlay ? 'absolute' : 'relative')};
  left: 0;
  margin: 0;

  &.hide {
    opacity: 0;
    z-index: -1;
  }

  &.show {
    opacity: 1;
    z-index: 2;
  }

  > [data-info] {
    ${infoDefault};
    top: -${props => props.theme.game.moveUp};

    &.layers-info-enter,
    &.layers-info2-enter,
    &.layers-info3-enter,
    &.layers-info4-enter,
    &.layers-info5-enter,
    &.layers-info6-enter {
      ${infoEnter};
    }

    &.layers-info-enter-done,
    &.layers-info-exit,
    &.layers-info2-enter-done,
    &.layers-info2-exit,
    &.layers-info3-enter-done,
    &.layers-info3-exit,
    &.layers-info4-enter-done,
    &.layers-info4-exit,
    &.layers-info5-enter-done,
    &.layers-info5-exit,
    &.layers-info6-enter-done,
    &.layers-info6-exit {
      ${infoEntered};
    }

    &.layers-info-exit-active,
    &.layers-info2-exit-active,
    &.layers-info3-exit-active,
    &.layers-info4-exit-active,
    &.layers-info5-exit-active,
    &.layers-info6-exit-active {
      ${infoExit};
    }
  }
`;

export const GameWrap = styled.section`
  ${sectionDefaultEase};
  transition-delay: 0.6s;

  &.game-enter-done {
    opacity: 1;
    transform: scale(1);
    z-index: ${props =>
      props.lower
        ? props.theme.zIndex.overlay
        : props.theme.zIndex.overlay + 1};
  }
`;

export const WarningWrap = styled.section`
  ${sectionDefaultEase};
  left: 50%;
  margin: 0;
  position: absolute;
  transform: scale(1.2) translateX(-50%);
  transform-origin: 0 0;
  width: 180px;

  @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
    width: 201px;
  }

  &.warning-enter-done {
    opacity: 1;
    margin: 0;
    transform: scale(1) translateX(-50%);
    z-index: ${props => props.theme.zIndex.overlay + 1};
  }

  &.lower {
    z-index: -1;
  }

  [data-info] {
    ${infoDefault};

    &.meanies-info-enter {
      ${infoEnter};
    }

    &.meanies-info-enter-done,
    &.meanies-info-exit {
      ${infoEntered};
    }

    &.meanies-info-exit-active {
      ${infoExit};
    }
  }
`;

export const Options = styled(Target)`
  position: relative;

  > [data-info] {
    ${infoDefault};
    top: 0;

    &.options-info-enter,
    &.options-info2-enter {
      ${infoEnter};
    }

    &.options-info-enter-done,
    &.options-info-exit,
    &.options-info2-enter-done,
    &.options-info2-exit {
      ${infoEntered};
    }

    &.options-info-exit-active,
    &.options-info2-exit-active {
      ${infoExit};
    }
  }
`;

export const OptionsWrap = styled.section`
  ${sectionDefault};
  transition-delay: 0.8s;

  &.options-enter-done {
    opacity: 1;
    transform: scale(1);
    z-index: ${props => props.theme.zIndex.overlay + 1};
  }
`;

export const Info = styled.div`
  background: ${props =>
    props.warn ? props.theme.colors.error : props.theme.helpOverlay.info.bg};
  box-shadow: ${props => props.theme.helpOverlay.info.boxShadow};
  border-radius: ${props => props.theme.helpOverlay.info.borderRadius};
  color: ${props =>
    props.warn ? props.theme.colors.copy : props.theme.helpOverlay.info.copy};
  font-size: calc(${props => props.theme.fonts.baseSize} - 1px);
  left: 50%;
  opacity: 0;
  padding: 5px 20px;
  position: absolute;
  top: -90px;
  transform: translate(-50%, 10px);
  transition: opacity ${props => props.theme.transition.duration}
      ${props => props.theme.transition.cubicBez},
    transform ${props => props.theme.transition.duration}
      ${props => props.theme.transition.cubicBez};
  width: 280px;
  z-index: -1;

  strong {
    color: ${props =>
      props.warn ? props.theme.colors.copy : props.theme.colors.purpleAA};
  }

  &::before {
    background: ${props =>
      props.warn ? props.theme.colors.error : props.theme.colors.copy};
    content: '';
    height: 20px;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -10px) rotate(-45deg);
    top: ${props => (props.arrow === 'top' ? 0 : '100%')};
    width: 20px;
  }

  .active & {
    align-items: center;
    display: flex;
    justify-content: center;
    z-index: ${props => props.theme.zIndex.overlay + 2};
  }
`;

export const OverviewTitle = styled.h2`
  background: ${props => props.theme.colors.bg};
  border-radius: 20px;
  color: ${props => props.theme.colors.copy};
  font-size: 13px;
  letter-spacing: 2px;
  margin: 0;
  padding: 5px 20px;
  position: absolute;
  text-transform: uppercase;
  top: -12px;
`;

export const OverviewWrap = styled.section`
  position: fixed;
  top: 50%;
  left: 50%;
  opacity: 0;
  position: absolute;
  transform: scale(1.5) translate(-50%, -50%);
  transform-origin: 0 0;
  transition: opacity ${props => props.theme.transition.duration}
      ${props => props.theme.transition.easeOut},
    transform ${props => props.theme.transition.duration}
      ${props => props.theme.transition.easeOut},
    z-index ${props => props.theme.transition.duration}
      ${props => props.theme.transition.easeOut};
  z-index: -1;

  &.overview-enter-done,
  &.overview-exit,
  &.overview2-enter-done,
  &.overview2-exit {
    align-items: center;
    display: flex;
    justify-content: center;
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
    transform-origin: 0 0;
    z-index: ${props => props.theme.zIndex.overlay + 3};
  }

  &.overview-exit-active,
  &.overview2-exit-active {
    align-items: center;
    display: flex;
    justify-content: center;
    opacity: 0;
    transform: translate(-50%, -50%) scale(1.5);
    transform-origin: 50% 50%;
  }
`;

const overviewWH = '200px';

export const OverviewInfo = styled.article`
  align-items: center;
  background: ${props => props.theme.helpOverlay.info.bg};
  border-radius: 30px;
  box-shadow: ${props => props.theme.helpOverlay.info.boxShadowLG};
  color: ${props => props.theme.helpOverlay.info.copy};
  display: flex;
  font-size: calc(${props => props.theme.fonts.baseSize} + 0.15vmin);
  flex-direction: column;
  height: ${overviewWH};
  justify-content: center;
  padding: 30px;
  top: 50%;
  width: ${overviewWH};
  z-index: -1;

  strong {
    color: ${props => props.theme.colors.purpleAA};
  }

  &.overview-info-enter-done,
  &.overview-info-exit,
  &.overview-info2-enter-done,
  &.overview-info2-exit {
    align-items: center;
    display: flex;
    justify-content: center;
    opacity: 1;
    z-index: ${props => props.theme.zIndex.overlay + 3};
  }

  &.overview-info-exit-active,
  &.overview-info2-exit-active {
    align-items: center;
    display: flex;
    justify-content: center;
    opacity: 0;
  }

  > button {
    bottom: 30px;
    left: 50%;
    margin: 0;
    position: absolute;
    transform: translateX(-50%);
  }
`;

const buttonSize = '32px';

export const Button = styled.button`
  background: ${props => props.theme.helpOverlay.button.bg};
  border-radius: 100%;
  border: 0;
  color: ${props => props.theme.helpOverlay.button.icon};
  cursor: pointer;
  flex: 0 0 ${buttonSize};
  height: ${buttonSize};
  margin: 0 0 0 20px;
  outline: 0;
  padding: 0;
  position: relative;
  width: ${buttonSize};

  &::before {
    background: ${props => props.theme.icons.ok};
    border-radius: 100%;
    content: '';
    height: ${buttonSize};
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: transform ${props => props.theme.transition.duration}
      ${props => props.theme.transition.cubicBez};
    width: ${buttonSize};
  }

  svg {
    fill: ${props => props.theme.helpOverlay.button.icon};
    transform: translate(0, 2px);
  }

  &:hover,
  &:focus {
    &::before {
      transform: translate(-50%, -50%) scale(1);
    }

    svg {
      fill: ${props => props.theme.helpOverlay.color};
    }
  }
`;

export const Title = styled.h1`
  font-size: calc(${props => props.theme.fonts.baseSize} + 1vmin);
  margin: 10px auto;
  position: absolute;
  text-align: center;
  top: 40px;

  @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
    top: 70px;
  }

  span {
    ${slantOffsetY};
    display: inline-block;
    opacity: 0;
    transition: opacity ${props => props.theme.transition.duration}
        ${props => props.theme.transition.cubicBez},
      transform ${props => props.theme.transition.duration}
        ${props => props.theme.transition.cubicBez};
  }

  .how::after,
  .title::after {
    background: ${props => props.theme.colors.bg};
    content: '';
    height: 2px;
    position: absolute;
    right: 5px;
    transition: width ${props => props.theme.transition.duration}
      ${props => props.theme.transition.cubicBez};
    transition-delay: 0.8s;
    width: 0;

    @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
      height: 3px;
    }
  }

  .how::after {
    bottom: -3px;
  }

  .title::after {
    top: -3px;
    transition-delay: 1s;
  }

  .title {
    color: ${props => props.theme.colors.copyAlt};
    transition-delay: 0.2s;
  }

  &.title-enter-done {
    span {
      ${slantNeutral};
      opacity: 1;
    }
    .how::after,
    .title::after {
      width: calc(30px + 2vmin);
    }
  }
`;

export const SubTitle = styled.h2`
  align-items: center;
  border-radius: 0 0 2px 2px;
  color: ${props => props.theme.colors.bg};
  display: flex;
  font-size: calc(${props => props.theme.fonts.baseSize} - 6px);
  font-weight: 700;
  justify-content: center;
  left: 50%;
  letter-spacing: 2px;
  margin: 0;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  position: fixed;
  text-transform: uppercase;
  top: 0;
  transform: translate(-50%, -10px);
  transition: opacity ${props => props.theme.transition.duration}
      ${props => props.theme.transition.easeOut},
    transform ${props => props.theme.transition.duration}
      ${props => props.theme.transition.easeOut};

  @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
    font-size: calc(${props => props.theme.fonts.baseSize} - 5px);
  }

  &.stage-title-enter-done {
    opacity: 1;
    transform: translate(-50%, 0);

    span.number {
      opacity: 1;
      transform: translateY(0);
    }
  }

  // Hide
  span.text {
    padding: 0;
    top: -50px;
    width: 0;
    overflow: hidden;
  }

  span.number {
    background: ${props => props.theme.colors.bg};
    color: ${props => props.theme.colors.copy};
    display: inline-block;
    opacity: 0;
    padding: 3px 6px 3px 8px;
    transform: translateY(10px);
    transition: opacity ${props => props.theme.transition.duration}
        ${props => props.theme.transition.easeOut},
      transform ${props => props.theme.transition.duration}
        ${props => props.theme.transition.cubicBez};
    transition-delay: ${props => props.theme.transition.duration};
  }
`;

export const PipWrap = styled.section`
  display: flex;
  left: 50%;
  position: fixed;
  bottom: 40px;
  transform: translateX(-50%);
  z-index: ${props => props.theme.zIndex.overlay + 2};
`;

export const Pip = styled.button`
  background: ${props =>
    props.active ? props.theme.colors.bg : props.theme.colors.copyLight};
  border-radius: 100%;
  border: 0;
  cursor: ${props => (props.active ? 'not-allowed' : 'pointer')};
  margin: 5px;
  outline: 0;
  padding: 5px;
  transition: background ${props => props.theme.transition.duration}
    ${props => props.theme.transition.easeOut};

  &:hover:not([disabled]),
  &:focus:not([disabled]) {
    background: ${props => props.theme.colors.copy};
  }
`;

export const HelpCarousel = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  transform: translateX(
    -${props => ((props.helpStage - 1) * 100) / props.helpTotal}%
  );
  transition: transform 0.75s ${props => props.theme.transition.easeOut};
  width: ${props => props.helpTotal * 100}vw;
`;

export const HelpCarouselItem = styled.div`
  opacity: ${props => (props.active ? 1 : 0)}
  position: relative;
  transition: opacity ${props => props.theme.transition.duration} ${props =>
  props.theme.transition.easeOut};
  width: 100vw;
`;

export const HelpCarouselWindow = styled.section`
  align-items: flex-start;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  overflow-x: hidden;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
`;

const Help = styled.article`
  align-items: center;
  background: ${props => props.theme.helpOverlay.bg} url(${bg}) center center /
    200%;
  bottom: 0;
  color: ${props => props.theme.helpOverlay.color};
  display: none;
  flex-direction: column;
  height: 100vh;
  min-height: 600px;
  justify-content: center;
  left: 0;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  text-align: center;
  transform: scale(0.8);
  transition: transform ${props => props.theme.transition.duration}
      ${props => props.theme.transition.easeOut},
    opacity ${props => props.theme.transition.duration}
      ${props => props.theme.transition.easeOut};
  z-index: ${props => props.theme.zIndex.overlay};

  @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
    min-height: 700px;
  }

  &.help-enter {
    display: flex;
  }

  &.help-enter-active,
  &.help-enter-done,
  &.help-exit {
    display: flex;
    opacity: 1;
    transform: scale(1);
  }

  &.help-exit-active {
    opacity: 0;
    transform: scale(0.8);
  }

  [data-main-block='1'] {
    .adding-layer & {
      animation: ${layerInOut} 3s ${props => props.theme.transition.easeOut}
        infinite;
    }
    .rotating & {
      animation: ${rotate90Staggered} 4s
        ${props => props.theme.transition.cubicBez} infinite;
    }
  }
`;

export default Help;
