import React from 'react';
import PropTypes from 'prop-types';
import IconRightStyled from './IconRight.styled';

const IconRight = props => (
  <IconRightStyled
    width={props.sizeWH}
    height={props.sizeWH}
    viewBox={props.viewBox}
    {...props}
  >
    <title>{props.title}</title>
    <path d="M8.122 24l-4.122-4 8-8-8-8 4.122-4 11.878 12z" />
  </IconRightStyled>
);

export default IconRight;

IconRight.propTypes = {
  title: PropTypes.string.isRequired,
  sizeWH: PropTypes.number,
  viewBox: PropTypes.string,
};

IconRight.defaultProps = {
  sizeWH: 24,
  viewBox: '-1 0 24 24',
};
