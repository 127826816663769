import styled from 'styled-components';

const IconDotsStyled = styled.svg`
  fill: ${props =>
    props.open ? props.theme.colors.copy : props.theme.burgerNav.svg};
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  outline: 0;
  transform: rotate(${props => (props.open ? '90deg' : '0')});
  transition: transform ${props => props.theme.transition.duration}
      ${props => props.theme.transition.easeOut},
    fill ${props => props.theme.transition.duration}
      ${props => props.theme.transition.easeOut};
`;

export default IconDotsStyled;
