import React from 'react';
import PropTypes from 'prop-types';
import IconFacebookStyled from './IconFacebook.styled';

const IconFacebook = props => (
  <IconFacebookStyled
    width={props.sizeWH}
    height={props.sizeWH}
    {...props}
    viewBox={props.viewBox}
  >
    <title>{props.title}</title>
    <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
  </IconFacebookStyled>
);

export default IconFacebook;

IconFacebook.propTypes = {
  sizeWH: PropTypes.number,
  title: PropTypes.string.isRequired,
  viewBox: PropTypes.string,
};

IconFacebook.defaultProps = {
  sizeWH: 24,
  viewBox: '0 0 24 24',
};
