import styled from 'styled-components';
import { rotate180Transition } from '../../../../utils/helpers/transitions';

const IconRotateStyled = styled.svg`
  fill: ${props =>
    props.light ? props.theme.inGameStats.icon : props.theme.icons.arrow.route};
  position: relative;

  ${rotate180Transition};
`;

export default IconRotateStyled;
