import styled from 'styled-components';

export const Content = styled.section`
  bottom: 0;
  height: 100%;
  left: 0;
  opacity: 0;
  right: 0;
  top: 0;
  transform: translateY(20px);
  transition: opacity ${props => props.theme.transition.duration}
      ${props => props.theme.transition.easeOut},
    transform ${props => props.theme.transition.duration}
      ${props => props.theme.transition.easeOut};
  width: 100%;

  .fade-enter-done & {
    opacity: 1;
    transform: translateY(0);
  }
`;

const RoutesTransition = styled.section`
  align-items: center;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100% - ${props => props.theme.header.height});
  left: 0;
  min-height: 500px;
  position: relative;
  right: 0;
  top: 0;

  @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
    min-height: 700px;
  }
`;

export default RoutesTransition;
