import { css } from 'styled-components';

export const rotate90Transition = css`
  &.rotate {
    pointer-events: none;
    transform: rotate(90deg);
    transition: transform ${props => props.theme.transition.rotationDuration}
      ${props => props.theme.transition.cubicBez};
  }
`;

export const rotate180Transition = css`
  &.rotate {
    pointer-events: none;
    transform: translate(-50%, -50%) rotate(180deg);
    transition: transform ${props => props.theme.transition.rotationDuration}
      ${props => props.theme.transition.cubicBez};
  }
`;

export const sideText = css`
  color: ${props => props.theme.colors.copyLight};
  font-size: 10px;
  font-weight: 700;
  left: -30px;
  letter-spacing: 1px;
  position: absolute;
  text-transform: uppercase;
  transform: rotate(-90deg);
`;
