import * as types from '../constants/stateConstants';
import { currentType, gameStages } from '../../utils/constants/constants';

export const appReducer = (state = {}, action) => {
  switch (action.type) {
    case types.PAGE_LOADED:
      return { ...state, pageReady: action.payload };
    case types.TOGGLE_MENU_OPEN:
      return { ...state, menuOpen: action.payload };
    case types.CHANGE_HELP_POSITION:
      return {
        ...state,
        help: {
          ...state.help,
          info: action.payload,
        },
      };
    case types.CHANGE_HELP_STAGE:
      return {
        ...state,
        help: {
          ...state.help,
          info:
            action.payload === 1 ? currentType.overview : currentType.overview2,
          stage: action.payload,
        },
      };
    case types.TOGGLE_VIEW_GAME:
      return {
        ...state,
        menuOpen: false,
        endgame: false,
        viewing: {
          ...state.viewing,
          game: action.payload,
        },
      };
    case types.TOGGLE_ENDGAME:
      return {
        ...state,
        endgame: action.payload.endgame,
        lastScore: action.payload.scores,
      };
    case types.SET_HELP_VIEWED:
      return {
        ...state,
        help: {
          ...state.help,
          viewed: action.payload,
        },
      };
    case types.SHOW_HELP_OVERLAY:
      return {
        ...state,
        help: {
          ...state.help,
          open: action.payload.open,
          stage: action.payload.level < gameStages.meanies ? 1 : 2,
          info:
            action.payload.level < gameStages.meanies
              ? currentType.overview
              : currentType.overview2,
        },
      };
    default:
      return state;
  }
};
