import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { hoverBefore } from '../../../utils/helpers/shared';

export const ButtonStyles = css`
  background: transparent;
  border: 0;
  color: ${props => props.theme.header.subNav.color};
  display: inline-block;
  font-size: calc(${props => props.theme.fonts.baseSize} - 3px);
  font-weight: 700;
  padding: 5px 10px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;

  &[disabled] {
    pointer-events: none;
  }

  &:hover,
  &:focus {
    color: ${props => props.theme.header.subNav.color};
    text-decoration: none;

    &::after {
      border-top: 3px solid ${props => props.theme.header.subNav.active};
      bottom: -3px;
      content: '';
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
      width: 10px;
    }
  }

  &.active::after {
    border-top: 3px solid ${props => props.theme.header.subNav.active};
    bottom: -3px;
    content: '';
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 30px;
  }
`;

export const BurgerButton = styled.button`
  background: transparent;
  border: 0;
  cursor: pointer;
  height: 20px;
  position: absolute;
  right: 20px;
  top: 50%;
  opacity: 0;
  outline: none;
  transform: translateY(-100%);
  transition: opacity ${props => props.theme.transition.duration}
      ${props => props.theme.transition.cubicBez},
    transform ${props => props.theme.transition.duration}
      ${props => props.theme.transition.cubicBez};
  transition-delay: 0.4s;
  width: 20px;
  z-index: ${props => props.theme.zIndex.overlay + 1};

  ${hoverBefore};

  .header-enter-done & {
    opacity: 1;
    transform: translateY(-50%);
  }

  &:focus {
    outline: 0;
    &::after {
      border-radius: 100%;
      border: 3px solid ${props => props.theme.colors.icon};
      bottom: -8px;
      content: '';
      left: -8px;
      position: absolute;
      right: -8px;
      top: -8px;
    }
  }
`;

const BurgerMenuStyled = styled.nav`
  height: ${props => props.theme.header.height};
  left: 0;
  position: fixed;
  display: none;
  align-items: center;
  justify-content: center;
  opacity: 0;
  right: 0;
  top: 0;
  transform: translateY(0);
  transition: opacity ${props => props.theme.transition.duration}
      ${props => props.theme.transition.easeOut},
    transform ${props => props.theme.transition.duration}
      ${props => props.theme.transition.cubicBez};
  z-index: -1;

  ul {
    align-items: center;
    display: flex;
    justify-content: center;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  &.slide-enter-active {
    display: flex;
    opacity: 0;
    transform: translateY(0);
  }
  &.slide-enter-done,
  &.slide-exit {
    display: flex;
    opacity: 1;
    transform: translateY(${props => props.theme.header.height});
  }
  &.slide-exit-active {
    display: flex;
    opacity: 0;
    transform: translateY(0);
  }
`;

export const Button = styled.button`
  ${ButtonStyles}
`;

export const Link = styled(NavLink)`
  ${ButtonStyles}
  background: ${props => props.theme.header.subNav.bg};
  border-radius: 40px;
  box-shadow: 0 0 0 rgba(0,0,0,0);
  padding: 5px 15px;
  transition: box-shadow ${props => props.theme.transition.duration} ${props =>
  props.theme.transition.easeOut};

  &::after {
    display: none;
  }

  &:hover,
  &:focus,
  &:active {
    outline: 0;
    box-shadow: 0 0 0 10px rgba(0,0,0,0.1);
  }
`;

export default BurgerMenuStyled;
