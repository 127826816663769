import styled from 'styled-components';
import { upDownCentered } from '../../../../utils/helpers/animations';

const IconUpStyled = styled.svg`
  animation: ${upDownCentered} 0.8s ${props => props.theme.transition.easeOut} 5;
  bottom: -30px;
  fill: ${props =>
    props.dark ? props.theme.colors.bg : props.theme.icons.arrow.route};
  left: 50%;
  position: absolute;
  top: ${props => (props.position === 'top' ? '-20px' : '97%')};
  transform: translateX(-50%);
`;

export default IconUpStyled;
