import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import BurgerNavStyled, { BurgerButton, Link } from './BurgerNav.styled';
import { toggleMenuOpen } from '../../../state/actions/toggleMenuOpen';
import IconDots from '../../1-atoms/icons/IconDots/IconDots';
import { transitionDuration } from '../../../utils/constants/constants';

const mapStateToProps = state => ({
  menuOpen: state.app.menuOpen,
  location: state.router.location.pathname,
});

const mapDispatchToProps = dispatch => ({
  toggleMenuOpen: bool => dispatch(toggleMenuOpen(bool)),
});

const toggleMenu = (menuOpen, toggleMenuOpen) => {
  toggleMenuOpen(!menuOpen);
};

class BurgerNav extends Component {
  componentDidUpdate(prevProps) {
    const { location, toggleMenuOpen } = this.props;
    if (prevProps.location !== location) {
      toggleMenuOpen(false); // Make sure menu closes on route change
    }
  }

  render() {
    const { location, menuOpen, toggleMenuOpen } = this.props;
    return (
      <Fragment>
        <BurgerButton
          id="menu-button"
          tabIndex={0}
          title="Toggle nav"
          aria-haspopup="true"
          onClick={() => toggleMenu(menuOpen, toggleMenuOpen)}
        >
          <IconDots open={menuOpen} />
        </BurgerButton>
        <CSSTransition
          in={menuOpen}
          classNames="slide"
          timeout={transitionDuration}
        >
          <BurgerNavStyled
            aria-labelledby="menu-button"
            aria-expanded={menuOpen}
          >
            <ul role="menubar">
              <li role="menuitem">
                <Link
                  {...location === '/about' && { disabled: true }}
                  to="/about"
                  exact
                  activeClassName="active"
                  title="About"
                  data-about-btn
                >
                  About
                </Link>
              </li>
            </ul>
          </BurgerNavStyled>
        </CSSTransition>
      </Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BurgerNav);

BurgerNav.propTypes = {
  toggleMenuOpen: PropTypes.func.isRequired,
  menuOpen: PropTypes.bool.isRequired,
  location: PropTypes.string.isRequired,
};
