import React, { useState } from 'react';
import PropTypes from 'prop-types';
import GameBlocks, {
  GameBlock,
  Block,
  Details,
  RemoveOption,
  OrderNum,
  GameOptionsCarousel,
  GameOptionsRow,
  GameOptionsItem,
  GameOptionsButton,
} from './GameBlockOptions.styled';
import {
  GameLayerRow,
  GameLayerCol,
} from '../../1-atoms/GoalBlock/GoalBlock.styled';
import {
  layerText,
  terms,
  optionsGroups,
} from '../../../utils/constants/constants';
import IconX from '../../1-atoms/icons/IconX/IconX';
import IconLock from '../../1-atoms/icons/IconLock/IconLock';
import IconLeft from '../../1-atoms/icons/IconLeft/IconLeft';
import IconRight from '../../1-atoms/icons/IconRight/IconRight';

const GameBlockOptions = ({
  gameBlocks,
  gameOptionsInUse,
  buttonsDisabled,
  win,
  fail,
  addLayerToStack,
  removeLayer,
  rotateBlocksActive,
}) => {
  const [optionsPos, setOptionsPos] = useState(1);

  const areOptionsSplit = gameBlocks.length > optionsGroups.minimum;
  const splitOptions = areOptionsSplit
    ? [
        gameBlocks.slice(0, gameBlocks.length / 2),
        gameBlocks.slice(gameBlocks.length / 2),
      ]
    : null;

  const handleButtonClick = (e, index) => {
    addLayerToStack(e, index);
  };

  const gameBlock = (bID, row, bIndex) => (
    <GameBlock data-block-option key={bID}>
      <Block
        onClick={e => handleButtonClick(e, bIndex)}
        title={
          bIndex === 0
            ? `${terms.options}: Block locked`
            : `${terms.options}: Block #${bIndex}`
        }
        className={bIndex === rotateBlocksActive.option && 'rotate'}
        disabled={buttonsDisabled || bIndex === 0 || win || fail}
        inUse={gameOptionsInUse.includes(bIndex)}
        data-block-button={bIndex}
      >
        {row.map(([{ rID, col }], rIndex) => (
          <GameLayerRow key={rID} active={gameOptionsInUse.includes(rIndex)}>
            {col.map(({ cID, type }) => (
              <GameLayerCol thumb key={cID} type={type} />
            ))}
          </GameLayerRow>
        ))}
      </Block>
      <Details>
        <OrderNum>
          {gameOptionsInUse.indexOf(bIndex) >= 0 &&
            gameOptionsInUse.indexOf(bIndex) + 1}
        </OrderNum>
        {bIndex === 0 && (
          <IconLock sizeWH={15} viewBox="0 0 35 32" title={layerText.locked} />
        )}
        {bIndex > 0 && gameOptionsInUse.includes(bIndex) && (
          <RemoveOption
            disabled={buttonsDisabled || win || fail}
            data-remove-single-layer
            onClick={() =>
              removeLayer({
                optionBlockIndex: bIndex,
                mainBlockIndex: gameOptionsInUse.indexOf(bIndex),
              })
            }
          >
            <IconX sizeWH={14} viewBox="-3 -4 30 30" title={layerText.remove} />
          </RemoveOption>
        )}
      </Details>
    </GameBlock>
  );

  return (
    <GameBlocks carousel={areOptionsSplit} data-game-option-wrap>
      {!areOptionsSplit ? (
        gameBlocks.map(({ bID, row }, bIndex) => gameBlock(bID, row, bIndex))
      ) : (
        <GameOptionsCarousel>
          <GameOptionsButton
            left
            title={optionsPos === 1 ? optionsGroups.noMore : optionsGroups.more}
            disabled={optionsPos === 1}
            onClick={() => setOptionsPos(optionsPos > 1 ? optionsPos - 1 : 1)}
          >
            <IconLeft
              title={
                optionsPos === 1 ? optionsGroups.noMore : optionsGroups.more
              }
              sizeWH={15}
            />
          </GameOptionsButton>
          <GameOptionsRow
            width={splitOptions.length * 100}
            position={(optionsPos - 1) * 100}
            total={splitOptions.length}
          >
            {splitOptions.map((arr, index) => (
              <GameOptionsItem
                aria-hidden={index + 1 !== optionsPos}
                key={`splitOption${index}`}
              >
                {arr.map(({ bID, row }, bIndex) => {
                  const opIndex = !index ? bIndex : bIndex + arr.length;
                  return gameBlock(bID, row, opIndex);
                })}
              </GameOptionsItem>
            ))}
          </GameOptionsRow>
          <GameOptionsButton
            right
            title={
              optionsPos === splitOptions.length
                ? optionsGroups.noMore
                : optionsGroups.more
            }
            disabled={optionsPos === splitOptions.length}
            onClick={() =>
              setOptionsPos(
                optionsPos < splitOptions.length
                  ? optionsPos + 1
                  : splitOptions.length
              )
            }
          >
            <IconRight
              title={
                optionsPos === splitOptions.length
                  ? optionsGroups.noMore
                  : optionsGroups.more
              }
              sizeWH={15}
            />
          </GameOptionsButton>
        </GameOptionsCarousel>
      )}
    </GameBlocks>
  );
};

export default GameBlockOptions;

GameBlockOptions.propTypes = {
  addLayerToStack: PropTypes.func.isRequired,
  removeLayer: PropTypes.func.isRequired,
  win: PropTypes.bool.isRequired,
  fail: PropTypes.bool.isRequired,
  gameBlocks: PropTypes.arrayOf(PropTypes.object).isRequired,
  gameOptionsInUse: PropTypes.arrayOf(PropTypes.number).isRequired,
  buttonsDisabled: PropTypes.bool.isRequired,
  rotateBlocksActive: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
};
