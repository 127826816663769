import React from 'react';
import PropTypes from 'prop-types';
import IconLeftStyled from './IconLeft.styled';

const IconLeft = props => (
  <IconLeftStyled
    width={props.sizeWH}
    height={props.sizeWH}
    viewBox={props.viewBox}
    {...props}
  >
    <title>{props.title}</title>
    <path d="M4,12,15.88,0,20,4l-8,8,8,8-4.12,4Z" />
  </IconLeftStyled>
);

export default IconLeft;

IconLeft.propTypes = {
  title: PropTypes.string.isRequired,
  sizeWH: PropTypes.number,
  viewBox: PropTypes.string,
};

IconLeft.defaultProps = {
  sizeWH: 24,
  viewBox: '1 0 24 24',
};
