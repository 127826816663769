import styled from 'styled-components';
import { rotate90AntiClockwiseLoop } from '../../../utils/helpers/animations';

export const Title = styled.h1`
  color: ${props => props.theme.colors.bg};
  font-size: calc(${props => props.theme.fonts.baseSize} + 0.2vmin);
  font-weight: 600;
  text-align: center;
`;

const PortraitOnlyStyled = styled.article`
  display: none;

  @media screen and (max-width: ${props =>
      props.theme.breakpoints.lg}) and (max-height: ${props =>
      props.theme.breakpoints.height.sm}) and (orientation: landscape) {
    align-items: center;
    background: ${props => props.theme.colors.icon};
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: ${props => props.theme.zIndex.overlay};

    svg {
      animation: ${rotate90AntiClockwiseLoop} 3s
        ${props => props.theme.transition.cubicBez} infinite;
    }
  }
`;

export default PortraitOnlyStyled;
