import React from 'react';
import PropTypes from 'prop-types';
import { Title } from './GameHeader.styled';

const GameHeader = ({ levelNo }) => (
  <Title title={`Level ${levelNo}`}>{levelNo}</Title>
);

export default GameHeader;

GameHeader.propTypes = {
  levelNo: PropTypes.number.isRequired,
};
