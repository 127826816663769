import styled, {css} from 'styled-components';

const helpStyles = css`
  left: 50%;
  position: absolute;
  top: 45px;
  transform: translateX(-50%);
`;

const EndGameMeaniesStyled = styled.section`
  display: flex;
  justify-content: center;
  margin: ${props => props.help ? '0' : '30px 15px 10px'};
  ${props => props.help && helpStyles}
`;

export default EndGameMeaniesStyled;
