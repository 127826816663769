import styled from 'styled-components';
import bg from '../../../utils/svgs/bg1.svg';

const AppStyled = styled.section`
  background: ${props => props.theme.colors.bg} url(${bg}) center center / 200%;
  height: 100%;
  opacity: ${props => (props.loading ? '0' : '1')};
  overflow-x: hidden;
  text-align: center;
  width: 100%;
`;

export default AppStyled;
