import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import OpenHelpStyled from './OpenHelp.styled';
import { showHelpOverlay } from '../../../state/actions/showHelpOverlay';
import IconHelp from '../icons/IconHelp/IconHelp';

const mapStateToProps = state => ({
  helpOpen: state.app.help.open,
  level: state.saved.current,
});

const mapDispatchToProps = dispatch => ({
  showHelpOverlay: obj => dispatch(showHelpOverlay(obj)),
});

const handleHelpToggle = (helpOpen, showHelpOverlay, level) => {
  showHelpOverlay({ open: !helpOpen, level });
};

const OpenHelp = ({ helpOpen, showHelpOverlay, level }) => (
  <OpenHelpStyled
    onClick={() => handleHelpToggle(helpOpen, showHelpOverlay, level)}
    title="Open help overlay"
  >
    <IconHelp title="Open help" />
  </OpenHelpStyled>
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OpenHelp);

OpenHelp.propTypes = {
  helpOpen: PropTypes.bool.isRequired,
  level: PropTypes.number.isRequired,
};
