import React, { memo } from 'react';
import PropTypes from 'prop-types';
import uuidv1 from 'uuid/v1';
import GoalBlockStyled, {
  GameLayerRow,
  GameLayerCol,
} from './GoalBlock.styled';
import { compareArrays } from '../../../utils/helpers/compareArrays';

const areEqual = (prevProps, nextProps) =>
  prevProps.win === nextProps.win &&
  compareArrays(prevProps.successOrder, nextProps.successOrder);

const GoalBlock = ({ successOrder, dark, win }) => (
  <GoalBlockStyled
    dark={dark}
    border
    title="Game target - replicate this to win!"
    win={win}
  >
    {successOrder.map(row => (
      <GameLayerRow key={uuidv1()}>
        {row.map(col => (
          <GameLayerCol goal key={uuidv1()} type={col} />
        ))}
      </GameLayerRow>
    ))}
  </GoalBlockStyled>
);

export default memo(GoalBlock, areEqual);

GoalBlock.propTypes = {
  successOrder: PropTypes.arrayOf(PropTypes.array).isRequired,
  dark: PropTypes.bool,
  win: PropTypes.bool,
};

GoalBlock.defaultProps = {
  dark: false,
  win: false,
};
