import React, { Suspense, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import RoutesTransition, { Content } from './Routes.styled';
import { loadDelay } from '../../../utils/constants/constants';
import Loading from '../../1-atoms/Loading/Loading';

// Use React.lazy for lazyload / code splitting
const Main = lazy(() => import('../../5-pages/Main/Main'));
const About = lazy(() => import('../../5-pages/About/About'));
const FourOhFour = lazy(() => import('../../5-pages/FourOhFour/FourOhFour'));

const mapStateToProps = state => ({
  pageReady: state.app.pageReady,
});

const Routes = ({ ...props }) => {
  const { location, pageReady } = props;
  const locationPath = location.pathname;

  return (
    <CSSTransition
      key={locationPath}
      in={!pageReady}
      classNames="fade"
      appear
      timeout={loadDelay}
    >
      <RoutesTransition className="fade">
        <Content>
          <Suspense fallback={<Loading />}>
            <Switch location={location}>
              <Route exact path="/" component={() => <Main />} />
              <Route path="/about" component={() => <About />} />
              <Route component={() => <FourOhFour />} />
            </Switch>
          </Suspense>
        </Content>
      </RoutesTransition>
    </CSSTransition>
  );
};

export default connect(mapStateToProps)(Routes);

Routes.propTypes = {
  pageReady: PropTypes.bool.isRequired,
  location: PropTypes.objectOf(PropTypes.string).isRequired,
};
