import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { slant } from '../../../utils/helpers/shared';

const styledButton = css`
  align-items: center;
  background: ${props => props.theme.header.bg};
  border: 0;
  cursor: pointer;
  display: inline-flex;
  font-weight: 500;
  height: ${props => props.theme.header.height};
  justify-content: center;
  opacity: 0;
  padding: 0;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  transform: translateY(-20px);
  transition: opacity ${props => props.theme.transition.duration}
      ${props => props.theme.transition.cubicBez},
    transform ${props => props.theme.transition.duration}
      ${props => props.theme.transition.cubicBez};
  z-index: ${props => props.theme.zIndex.overlay};

  &[disabled] {
    pointer-events: none;
  }

  &.active {
    color: ${props => props.theme.links.active};
  }

  &:hover {
    text-decoration: none;
  }

  &:focus {
    outline: 0;
    &::before {
      content: '';
      border-radius: 50px;
      bottom: 14px;
      box-shadow: 0 0 0 3px ${props => props.theme.colors.icon};
      left: -5px;
      position: absolute;
      right: -5px;
      top: 14px;
    }
  }

  .header-enter-done & {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Logo = styled.span`
  background: ${props => props.theme.header.logo.bg};
  border-radius: 1px;
  border: 3px solid ${props => props.theme.header.logo.border};
  color: ${props => props.theme.header.logo.color}
  font-size: calc(${props => props.theme.fonts.baseSize} - 3px);
  font-weight: 700;
  padding: 5px 10px;
  position: relative;
  text-transform: lowercase;
  top: 0;
  ${slant};

  span {
    position: relative;
    top: 2px;
  }

  &:hover {
    text-decoration: none;
  }
`;

export const Link = styled(NavLink)`
  ${styledButton};
`;

export const LogoWrap = styled.span`
  align-items: center;
  display: inline-flex;
  height: ${props => props.theme.header.height};
  justify-content: center;
  z-index: ${props => props.theme.zIndex.overlay};
`;

export const Home = styled.button`
  ${styledButton};
`;

const HeaderStyled = styled.header`
  height: ${props => props.theme.header.height};
  left: 50%;
  max-width: 400px;
  padding: 0;
  position: relative;
  top: 0;
  transform: translateX(-50%);
  z-index: ${props => props.theme.zIndex.overlay};
  width: 100%;
`;

export default HeaderStyled;
