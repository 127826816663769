import React from 'react';
import PropTypes from 'prop-types';
import Title from './PageTitle.styled';

const PageTitle = ({ title, type, ...props }) => <Title type={type}>{title}</Title>;

export default PageTitle;

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
};

PageTitle.defaultProps = {
  type: '',
};
