import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { appReducer as app } from '../reducers/appReducer';
import { savedReducer as saved } from '../reducers/savedReducer';

export default history =>
  combineReducers({
    router: connectRouter(history),
    app,
    saved,
  });
