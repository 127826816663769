import { data } from './data';
import { currentType } from '../../utils/constants/constants';

const initState = {
  router: {
    location: {
      pathname: '/',
    },
  },
  app: {
    pageReady: false,
    data,
    gameCount: data.length - 1,
    menuOpen: false,
    help: {
      open: false,
      info: currentType.overview,
      stage: 1,
      stageTotal: 2,
      viewed: false,
    },
    totalStars: data.length * 3 - 3,
    endgame: false,
    viewing: {
      game: false,
    },
  },
  saved: {
    current: 0,
    dir: '',
    scores: data.map(() => ({
      starRating: 0,
      timeTaken: 9999,
      rotations: 9999,
      removals: 9999,
    })),
    starsEarned: 0,
    levels: {
      progress: 1,
      unlocked: false,
    },
  },
};

export default initState;
