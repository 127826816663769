import React from 'react';
import { CSSTransition } from 'react-transition-group';
import IconMeanieStyled from './IconMeanie.styled';
import { loadDelay } from '../../../../utils/constants/constants';

const IconMeanie = props => (
  <CSSTransition appear timeout={loadDelay}>
    <IconMeanieStyled width={props.sizeWH} height={props.sizeWH} {...props} />
  </CSSTransition>
);

export default IconMeanie;
