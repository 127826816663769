import styled from 'styled-components';
import { hoverBefore } from '../../../utils/helpers/shared';

const OpenHelpStyled = styled.button`
  background: transparent;
  border: 0;
  color: #fff;
  cursor: pointer;
  padding: 0;
  position: absolute;
  top: 18px;
  right: 20px;
  z-index: ${props => props.theme.zIndex.overlay};

  svg {
    width: 15px;
    fill: ${props => props.theme.icons.arrow.default};
  }

  &:focus {
    outline: 0;

    &::after {
      border-radius: 50px;
      box-shadow: 0 0 0 3px ${props => props.theme.colors.icon};
      content: '';
      height: 30px;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 30px;
    }
  }

  ${hoverBefore};
`;

export default OpenHelpStyled;
