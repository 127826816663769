import React from 'react';
import PropTypes from 'prop-types';
import SVG from './IconTick.styled';

const IconTick = props => (
  <SVG
    width={props.sizeWH}
    height={props.sizeWH}
    viewBox="2 2 20 20"
    {...props}
  >
    <title>{props.title}</title>
    <path d="M10 17l-4.5-4.3 1.4-1.4 3.1 2.9L17 7l1.4 1.4c.1 0-8.4 8.6-8.4 8.6z" />
  </SVG>
);

export default IconTick;

IconTick.propTypes = {
  sizeWH: PropTypes.number,
  title: PropTypes.string.isRequired,
};

IconTick.defaultProps = {
  sizeWH: 30,
};
