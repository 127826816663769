import React from 'react';
import IconDotsStyled from './IconDots.styled';

const IconDots = props => (
  <IconDotsStyled viewBox="-1 0 25 25" {...props}>
    <path d="M12 18a3 3 0 1 1 0 6 3 3 0 0 1 0-6zm0-9a3 3 0 1 1 0 6 3 3 0 0 1 0-6zm0-9a3 3 0 1 1 0 6 3 3 0 0 1 0-6z" />
  </IconDotsStyled>
);

export default IconDots;
