import React from 'react';
import PortraitOnlyStyled, { Title } from './PortraitOnly.styled';
import IconMobile from '../icons/IconMobile/IconMobile';

const PortraitOnly = () => (
  <PortraitOnlyStyled>
    <IconMobile sizeWH={35} title="Device" invert />
    <Title>Please turn your device to Portrait mode</Title>
  </PortraitOnlyStyled>
);

export default PortraitOnly;
