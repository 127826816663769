import React from 'react';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { ConnectedRouter } from 'connected-react-router';
import WebFont from 'webfontloader';
import { Route } from 'react-router-dom';
import { history } from '../../../state/store/store';
import GATracker from '../../../utils/helpers/GATracker';
import theme from '../../../theme/theme';
import Routes from '../Routes/Routes';
import Header from '../../2-molecules/Header/Header';
import AppStyled from './App.styled';
import ScrollToTop from '../../../utils/helpers/ScrollToTop';
import ErrorBoundary from '../../2-molecules/ErrorBoundary/ErrorBoundary';
import PortraitOnly from '../../1-atoms/PortraitOnly/PortraitOnly';
import ShareResult from '../../2-molecules/ShareResult/ShareResult';
import HelpOverlay from '../../3-organisms/HelpOverlay/HelpOverlay';

const GlobalStyle = createGlobalStyle`
  body {
    background: #8e79f7;
    color: #fff;
    margin: 0;
    height: 100vh;
    padding: 0;
    font-family: "Nunito", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;
  }

  @media screen and (min-width: 768px) {
    min-height: 600px;
  }

  #root {
    height: 100%;
  }

  a {
    color: #61dafb;
    text-decoration: none;
  }

  a:hover {
    color: #fff;
    text-decoration: underline;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }
`;

WebFont.load({
  google: {
    families: ['Nunito:400,600,900'],
  },
});

const App = () => (
  <ConnectedRouter history={history}>
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        <ScrollToTop>
          <AppStyled>
            <Header location={history.location.pathname} />
            <Route path="/" component={GATracker(Routes)} />
            <PortraitOnly />
            <ShareResult />
          </AppStyled>
          <HelpOverlay />
        </ScrollToTop>
        <GlobalStyle />
      </ErrorBoundary>
    </ThemeProvider>
  </ConnectedRouter>
);

export default App;
