import React from 'react';
import PropTypes from 'prop-types';
import SVG from './IconX.styled';

const IconX = props => (
  <SVG
    width={props.sizeWH}
    height={props.sizeWH}
    viewBox={props.viewBox}
    {...props}
  >
    <title>{props.title}</title>
    <path d="M24 20.188l-8.315-8.209 8.2-8.282L20.188 0l-8.212 8.318L3.666.115 0 3.781l8.321 8.24-8.206 8.313L3.781 24l8.237-8.318 8.285 8.203z" />
  </SVG>
);

export default IconX;

IconX.propTypes = {
  sizeWH: PropTypes.number,
  viewBox: PropTypes.string,
  title: PropTypes.string.isRequired,
};

IconX.defaultProps = {
  sizeWH: 24,
  viewBox: '-5 -7 30 30',
};
