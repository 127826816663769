import {
  challengeTypes,
  strapline,
  subtitle,
  doop,
} from '../../utils/constants/constants';

export const data = [
  {
    doop: 'INTRO',
    title: doop,
    subtitle,
    text: strapline,
  },
  {
    doop: 1,
    challenge: challengeTypes.starter,
    text: '-',
    // prettier-ignore
    gameBlocks: [
      [
        [0,2,2],
        [0,1,2],
        [0,0,0],
      ],
      [
        [2,0,0],
        [5,5,5],
        [0,5,0],
      ],
      [
        [0,0,0],
        [0,0,0],
        [2,2,2],
      ],
    ],
    // prettier-ignore
    successOrder: [
      [2,2,2],
      [5,5,5],
      [2,2,2],
    ],
    meta: {
      time: 40,
      rotations: 10,
      removals: 3,
    },
  },
  {
    doop: 2,
    challenge: challengeTypes.starter,
    text: '-',
    // prettier-ignore
    gameBlocks: [
      [
        [0,3,0],
        [3,0,0],
        [2,3,0],
      ],
      [
        [6,0,0],
        [0,0,1],
        [1,0,6],
      ],
      [
        [0,0,6],
        [0,0,3],
        [6,0,0],
      ],
    ],
    // prettier-ignore
    successOrder: [
      [6,3,6],
      [3,0,3],
      [6,3,6],
    ],
    meta: {
      time: 40,
      rotations: 10,
      removals: 3,
    },
  },
  {
    doop: 3,
    challenge: challengeTypes.easy,
    text: '-',
    // prettier-ignore
    gameBlocks: [
      [
        [0,1,6],
        [5,2,0],
        [0,3,1],
      ],
      [
        [2,0,0],
        [1,0,5],
        [3,1,0],
      ],
      [
        [1,0,0],
        [0,0,1],
        [6,0,0],
      ],
    ],
    // prettier-ignore
    successOrder: [
      [1,1,6],
      [1,2,1],
      [6,1,1],
    ],
    meta: {
      time: 60,
      rotations: 10,
      removals: 3,
    },
  },
  {
    doop: 4,
    challenge: challengeTypes.easy,
    text: '-',
    // prettier-ignore
    gameBlocks: [
      [
        [2,1,6],
        [5,3,0],
        [0,4,4],
      ],
      [
        [0,4,2],
        [1,0,0],
        [3,5,2],
      ],
      [
        [0,2,0],
        [0,0,2],
        [0,1,0],
      ],
    ],
    // prettier-ignore
    successOrder: [
      [2,2,2],
      [1,3,2],
      [3,1,2],
    ],
    meta: {
      time: 60,
      rotations: 10,
      removals: 3,
    },
  },
  {
    doop: 5,
    challenge: challengeTypes.easy,
    text: '-',
    // prettier-ignore
    gameBlocks: [
      [
        [2,1,6],
        [5,5,0],
        [1,2,3],
      ],
      [
        [5,0,2],
        [3,0,0],
        [5,1,0],
      ],
      [
        [0,0,0],
        [2,0,0],
        [0,0,2],
      ],
    ],
    // prettier-ignore
    successOrder: [
      [5,1,2],
      [2,5,0],
      [5,1,2],
    ],
    meta: {
      time: 60,
      rotations: 10,
      removals: 3,
    },
  },
  {
    doop: 6,
    challenge: challengeTypes.easy,
    text: '-',
    // prettier-ignore
    gameBlocks: [
      [
        [2,1,6],
        [6,5,0],
        [5,2,3],
      ],
      [
        [5,0,5],
        [0,0,2],
        [0,1,2],
      ],
      [
        [1,0,0],
        [0,0,6],
        [0,0,1],
      ],
    ],
    // prettier-ignore
    successOrder: [
      [1,1,5],
      [6,5,6],
      [5,1,1],
    ],
    meta: {
      time: 60,
      rotations: 10,
      removals: 3,
    },
  },
  {
    doop: 7,
    challenge: challengeTypes.easy,
    text: '-',
    // prettier-ignore
    gameBlocks: [
      [
        [4,1,6],
        [5,3,0],
        [0,4,1],
      ],
      [
        [1,0,1],
        [4,0,5],
        [4,1,0],
      ],
      [
        [0,0,0],
        [0,0,4],
        [1,0,0],
      ],
    ],
    // prettier-ignore
    successOrder: [
      [1,1,1],
      [4,3,4],
      [1,1,1],
    ],
    meta: {
      time: 60,
      rotations: 10,
      removals: 3,
    },
  },
  {
    doop: 8,
    challenge: challengeTypes.easyish,
    text: '-',
    // prettier-ignore
    gameBlocks: [
      [
        [3,0,5],
        [0,4,1],
        [0,0,1],
      ],
      [
        [0,0,0],
        [1,3,0],
        [4,1,0],
      ],
      [
        [0,0,4],
        [4,3,0],
        [1,0,3],
      ],
      [
        [0,1,6],
        [6,0,0],
        [0,0,0],
      ],
    ],
    // prettier-ignore
    successOrder: [
      [3,1,6],
      [6,3,1],
      [1,1,3],
    ],
    meta: {
      time: 60,
      rotations: 10,
      removals: 4,
    },
  },
  {
    doop: 9,
    challenge: challengeTypes.easyish,
    text: '-',
    // prettier-ignore
    gameBlocks: [
      [
        [4,0,0],
        [0,4,1],
        [2,4,1],
      ],
      [
        [3,0,6],
        [1,3,4],
        [3,1,4],
      ],
      [
        [0,1,0],
        [2,3,0],
        [0,0,3],
      ],
      [
        [0,1,0],
        [1,5,0],
        [0,0,6],
      ],
    ],
    // prettier-ignore
    successOrder: [
      [3,1,6],
      [1,5,4],
      [3,1,6],
    ],
    meta: {
      time: 80,
      rotations: 15,
      removals: 4,
    },
  },
  {
    doop: 10,
    text: '-',
    challenge: challengeTypes.easyish,
    // prettier-ignore
    gameBlocks: [
      [
        [1,0,3],
        [2,4,1],
        [0,3,1],
      ],
      [
        [0,2,1],
        [1,3,2],
        [0,1,3],
      ],
      [
        [0,0,0],
        [2,3,0],
        [1,3,0],
      ],
      [
        [0,1,0],
        [0,2,0],
        [3,0,0],
      ],
    ],
    // prettier-ignore
    successOrder: [
      [1,1,1],
      [2,2,2],
      [3,3,3],
    ],
    meta: {
      time: 80,
      rotations: 15,
      removals: 5,
    },
  },
  {
    doop: 11,
    text: '-',
    challenge: challengeTypes.easyish,
    // prettier-ignore
    gameBlocks: [
      [
        [0,0,4],
        [2,3,0],
        [0,0,1],
      ],
      [
        [2,0,0],
        [0,0,1],
        [1,1,4],
      ],
      [
        [0,0,1],
        [0,0,4],
        [1,0,1],
      ],
      [
        [0,3,0],
        [0,0,1],
        [1,1,0],
      ]
    ],
    // prettier-ignore
    successOrder: [
      [2,3,1],
      [2,3,1],
      [1,1,1],
    ],
    meta: {
      time: 80,
      rotations: 15,
      removals: 4,
    },
  },
  {
    doop: 12,
    text: '-',
    challenge: challengeTypes.easyish,
    // prettier-ignore
    gameBlocks: [
      [
        [4,0,4],
        [2,0,0],
        [0,3,3],
      ],
      [
        [2,0,1],
        [5,0,1],
        [1,0,4],
      ],
      [
        [1,0,0],
        [0,2,3],
        [1,0,1],
      ],
      [
        [0,3,0],
        [3,0,0],
        [1,0,0],
      ]
    ],
    // prettier-ignore
    successOrder: [
      [1,3,1],
      [3,2,3],
      [1,3,1],
    ],
    meta: {
      time: 80,
      rotations: 15,
      removals: 5,
    },
  },
  {
    doop: 13,
    challenge: challengeTypes.mediumish,
    text: '-',
    // prettier-ignore
    gameBlocks: [
      [
        [1,0,4],
        [2,4,0],
        [0,0,3],
      ],
      [
        [2,0,3],
        [3,0,1],
        [1,3,0],
      ],
      [
        [1,2,0],
        [0,0,0],
        [4,2,0],
      ],
      [
        [0,0,0],
        [0,2,0],
        [1,0,0],
      ]
    ],
    // prettier-ignore
    successOrder: [
      [1,2,3],
      [3,2,1],
      [1,2,3],
    ],
    meta: {
      time: 80,
      rotations: 15,
      removals: 4,
    },
  },
  {
    doop: 14,
    text: '-',
    challenge: challengeTypes.mediumish,
    // prettier-ignore
    gameBlocks: [
      [
        [5,0,4],
        [2,4,0],
        [0,0,1],
      ],
      [
        [0,0,3],
        [5,2,5],
        [1,3,0],
      ],
      [
        [0,1,1],
        [0,5,0],
        [4,1,0],
      ],
      [
        [0,0,0],
        [0,0,0],
        [1,0,5],
      ]
    ],
    // prettier-ignore
    successOrder: [
      [5,1,1],
      [5,5,5],
      [1,1,5],
    ],
    meta: {
      time: 80,
      rotations: 15,
      removals: 4,
    },
  },
  {
    doop: 15,
    text: '-',
    challenge: challengeTypes.mediumish,
    // prettier-ignore
    gameBlocks: [
      [
        [4,0,1],
        [2,4,0],
        [0,0,1],
      ],
      [
        [0,2,4],
        [5,2,5],
        [1,3,2],
      ],
      [
        [0,0,2],
        [0,2,0],
        [4,1,0],
      ],
      [
        [1,0,0],
        [2,0,1],
        [1,2,0],
      ]
    ],
    // prettier-ignore
    successOrder: [
      [1,2,2],
      [2,2,1],
      [1,2,2],
    ],
    meta: {
      time: 80,
      rotations: 15,
      removals: 4,
    },
  },
  {
    doop: 16,
    text: '-',
    challenge: challengeTypes.mediumish,
    // prettier-ignore
    gameBlocks: [
      [
        [1,0,1],
        [3,1,0],
        [0,0,1],
      ],
      [
        [0,3,4],
        [2,2,5],
        [4,3,1],
      ],
      [
        [0,0,2],
        [0,2,3],
        [2,2,0],
      ],
      [
        [0,0,3],
        [2,1,0],
        [0,2,0],
      ]
    ],
    // prettier-ignore
    successOrder: [
      [1,3,3],
      [2,1,3],
      [2,2,1],
    ],
    meta: {
      time: 80,
      rotations: 15,
      removals: 4,
    },
  },
  {
    doop: 17,
    text: '-',
    challenge: challengeTypes.mediumish,
    // prettier-ignore
    gameBlocks: [
      [
        [5,0,4],
        [3,1,0],
        [0,1,1],
      ],
      [
        [0,3,3],
        [1,2,4],
        [4,0,0],
      ],
      [
        [0,0,0],
        [0,0,3],
        [5,0,4],
      ],
      [
        [0,1,0],
        [0,0,3],
        [0,0,3],
      ]
    ],
    // prettier-ignore
    successOrder: [
      [5,1,3],
      [1,2,3],
      [5,1,3],
    ],
    meta: {
      time: 80,
      rotations: 15,
      removals: 5,
    },
  },
  {
    doop: 18,
    text: '-',
    challenge: challengeTypes.medium,
    // prettier-ignore
    gameBlocks: [
      [
        [5,0,4,1],
        [3,1,0,1],
        [0,0,0,1],
        [0,1,0,1],
      ],
      [
        [0,3,3,1],
        [1,2,4,3],
        [4,0,0,1],
        [4,0,0,1],
      ],
      [
        [0,0,0,2],
        [0,0,3,0],
        [4,2,4,1],
        [5,0,4,1],
      ],
      [
        [0,1,0,2],
        [0,0,0,0],
        [5,0,5,2],
        [0,0,3,1],
      ]
    ],
    // prettier-ignore
    successOrder: [
      [5,1,3,2],
      [1,2,3,3],
      [5,2,5,2],
      [5,1,3,1],
    ],
    meta: {
      time: 90,
      rotations: 12,
      removals: 5,
    },
  },
  {
    doop: 19,
    text: '-',
    challenge: challengeTypes.medium,
    // prettier-ignore
    gameBlocks: [
      [
        [5,0,4,0],
        [3,1,0,0],
        [6,0,0,1],
        [0,1,5,1],
      ],
      [
        [0,3,3,0],
        [1,2,4,3],
        [0,5,0,1],
        [1,0,1,4],
      ],
      [
        [0,0,5,1],
        [0,3,3,0],
        [0,0,4,1],
        [0,4,0,3],
      ],
      [
        [3,0,0,0],
        [0,0,0,6],
        [0,0,3,3],
        [0,5,0,0],
      ]
    ],
    // prettier-ignore
    successOrder: [
      [3,3,5,1],
      [1,3,3,6],
      [6,5,3,3],
      [1,5,1,3],
    ],
    meta: {
      time: 90,
      rotations: 12,
      removals: 5,
    },
  },
  {
    doop: 20,
    text: '-',
    challenge: challengeTypes.medium,
    // prettier-ignore
    gameBlocks: [
      [
        [1,0,4,0],
        [3,4,0,0],
        [6,0,3,4],
        [0,1,5,1],
      ],
      [
        [0,0,1,3],
        [1,2,3,3],
        [0,5,0,1],
        [1,0,1,4],
      ],
      [
        [0,4,0,1],
        [0,3,0,1],
        [1,3,0,0],
        [0,0,0,3],
      ],
      [
        [0,1,0,0],
        [3,0,0,0],
        [0,0,0,3],
        [0,0,0,1],
      ]
    ],
    // prettier-ignore
    successOrder: [
      [1,1,1,1],
      [3,3,3,1],
      [1,3,3,3],
      [1,1,1,1],
    ],
    meta: {
      time: 90,
      rotations: 12,
      removals: 5,
    },
  },
  {
    doop: 21,
    text: '-',
    challenge: challengeTypes.medium,
    // prettier-ignore
    gameBlocks: [
      [
        [1,0,4,4],
        [3,4,0,0],
        [6,0,4,4],
        [0,1,1,1],
      ],
      [
        [0,1,1,3],
        [0,2,3,3],
        [0,3,6,1],
        [1,1,0,2],
      ],
      [
        [4,0,0,1],
        [0,4,0,1],
        [1,0,0,0],
        [0,0,0,0],
      ],
      [
        [2,0,0,2],
        [1,3,0,0],
        [0,0,3,0],
        [2,0,0,0],
      ]
    ],
    // prettier-ignore
    successOrder: [
      [2,1,1,2],
      [1,3,3,1],
      [1,3,3,1],
      [2,1,1,2],
    ],
    meta: {
      time: 90,
      rotations: 12,
      removals: 5,
    },
  },
  {
    doop: 22,
    text: '-',
    challenge: challengeTypes.medium,
    // prettier-ignore
    gameBlocks: [
      [
        [1,2,4,4],
        [3,3,0,0],
        [6,0,1,2],
        [2,4,1,1],
      ],
      [
        [0,0,2,3],
        [0,2,1,3],
        [2,3,0,0],
        [1,1,2,2],
      ],
      [
        [4,0,0,1],
        [0,4,0,2],
        [0,1,0,0],
        [0,3,0,4],
      ],
      [
        [1,0,0,4],
        [2,1,0,0],
        [0,0,0,0],
        [4,0,0,1],
      ]
    ],
    // prettier-ignore
    successOrder: [
      [1,2,2,4],
      [2,1,1,2],
      [2,1,1,2],
      [4,3,2,1],
    ],
    meta: {
      time: 90,
      rotations: 12,
      removals: 5,
    },
  },
  {
    doop: 23,
    text: '-',
    challenge: challengeTypes.medium,
    // prettier-ignore
    gameBlocks: [
      [
        [1,2,4,4],
        [3,3,2,0],
        [4,0,1,2],
        [2,4,1,1],
      ],
      [
        [4,0,2,3],
        [5,3,0,3],
        [2,4,4,0],
        [5,1,2,2],
      ],
      [
        [2,0,0,5],
        [0,0,0,2],
        [5,2,0,5],
        [0,3,0,4],
      ],
      [
        [5,0,3,0],
        [0,0,0,5],
        [0,0,3,0],
        [0,0,0,5],
      ]
    ],
    // prettier-ignore
    successOrder: [
      [5,2,3,5],
      [5,3,2,5],
      [5,2,3,5],
      [5,3,2,5],
    ],
    meta: {
      time: 90,
      rotations: 12,
      removals: 5,
    },
  },
  {
    doop: 24,
    text: '-',
    challenge: challengeTypes.medium,
    // prettier-ignore
    gameBlocks: [
      [
        [6,0,4,4],
        [3,3,2,0],
        [4,3,1,2],
        [0,4,1,0],
      ],
      [
        [0,4,2,6],
        [5,6,0,5],
        [2,6,4,1],
        [0,3,2,2],
      ],
      [
        [0,3,0,0],
        [3,0,0,3],
        [4,0,0,5],
        [6,0,0,6],
      ],
      [
        [0,0,3,0],
        [0,0,6,0],
        [3,0,6,3],
        [0,0,3,0],
      ]
    ],
    // prettier-ignore
    successOrder: [
      [6,3,3,6],
      [3,6,6,3],
      [3,6,6,3],
      [6,3,3,6],
    ],
    meta: {
      time: 90,
      rotations: 12,
      removals: 5,
    },
  },
  {
    doop: 25,
    text: '-',
    challenge: challengeTypes.medium,
    // prettier-ignore
    gameBlocks: [
      [
        [1,5,3,4],
        [3,1,2,0],
        [4,3,1,2],
        [3,1,1,4],
      ],
      [
        [0,0,2,3],
        [1,0,0,5],
        [2,3,4,1],
        [0,3,2,2],
      ],
      [
        [0,0,0,0],
        [0,0,0,3],
        [4,0,3,0],
        [0,4,0,1],
      ],
      [
        [0,0,4,0],
        [0,0,3,0],
        [3,0,1,0],
        [0,0,5,0],
      ]
    ],
    // prettier-ignore
    successOrder: [
      [1,5,4,3],
      [1,1,3,3],
      [3,3,1,1],
      [3,4,5,1],
    ],
    meta: {
      time: 60,
      rotations: 12,
      removals: 5,
    },
  },
  {
    doop: 26,
    text: '-',
    challenge: challengeTypes.hard,
    // prettier-ignore
    gameBlocks: [
      [
        [0,2,0,0],
        [3,1,2,0],
        [0,1,2,0],
        [1,0,0,0],
      ],
      [
        [0,0,4,0],
        [0,0,0,5],
        [0,6,4,1],
        [0,3,3,0],
      ],
      [
        [0,0,2,0],
        [0,2,0,4],
        [4,0,6,4],
        [0,4,2,0],
      ],
      [
        [0,0,0,0],
        [0,6,3,0],
        [3,0,0,0],
        [0,0,0,0],
      ],
      [
        [0,0,0,0],
        [0,0,5,0],
        [4,0,0,3],
        [0,2,0,0],
      ],
      [
        [0,0,0,0],
        [0,0,0,2],
        [2,5,0,0],
        [0,0,0,0],
      ]
    ],
    // prettier-ignore
    successOrder: [
      [0,2,2,0],
      [3,6,5,2],
      [2,5,6,3],
      [1,2,2,0],
    ],
    meta: {
      time: 180,
      rotations: 20,
      removals: 8,
    },
  },
  {
    doop: 27,
    text: '-',
    challenge: challengeTypes.hard,
    // prettier-ignore
    gameBlocks: [
      [
        [0,2,0,0],
        [4,0,1,1],
        [0,1,0,0],
        [4,0,0,4],
      ],
      [
        [4,0,0,0],
        [1,0,5,0],
        [6,0,2,1],
        [5,4,0,0],
      ],
      [
        [0,0,2,0],
        [0,6,0,0],
        [0,0,1,0],
        [0,1,0,3],
      ],
      [
        [0,0,1,0],
        [0,0,0,4],
        [0,4,3,0],
        [0,0,1,0],
      ],
      [
        [1,0,0,5],
        [0,3,0,0],
        [2,0,0,0],
        [0,0,2,0],
      ],
      [
        [3,0,0,0],
        [0,0,0,2],
        [0,5,0,0],
        [0,0,0,0],
      ]
    ],
    // prettier-ignore
    successOrder: [
      [3,2,1,5],
      [1,3,5,2],
      [2,5,3,1],
      [5,1,2,3],
    ],
    meta: {
      time: 180,
      rotations: 20,
      removals: 8,
    },
  },
  {
    doop: 28,
    text: '-',
    challenge: challengeTypes.hard,
    // prettier-ignore
    gameBlocks: [
      [
        [1,0,6,0],
        [4,0,0,1],
        [0,0,4,0],
        [1,0,0,4],
      ],
      [
        [0,1,5,0],
        [2,3,0,4],
        [0,0,2,0],
        [6,0,2,0],
      ],
      [
        [0,0,0,4],
        [0,1,6,0],
        [5,0,0,0],
        [1,4,0,0],
      ],
      [
        [0,4,0,1],
        [0,0,0,2],
        [2,6,0,0],
        [0,2,0,0],
      ],
      [
        [0,0,0,0],
        [0,5,5,0],
        [0,4,0,1],
        [0,0,0,0],
      ],
      [
        [0,3,3,0],
        [0,0,5,0],
        [0,2,0,2],
        [4,0,0,0],
      ]
    ],
    // prettier-ignore
    successOrder: [
        [1,3,3,1],
        [2,5,5,2],
        [2,2,2,2],
        [4,2,2,4],
    ],
    meta: {
      time: 180,
      rotations: 20,
      removals: 8,
    },
  },
  {
    doop: 29,
    text: '-',
    challenge: challengeTypes.hard,
    // prettier-ignore
    gameBlocks: [
      [
        [3,5,0,0],
        [0,2,0,0],
        [0,0,1,0],
        [0,0,0,4],
      ],
      [
        [2,0,1,5],
        [0,5,0,4],
        [0,0,0,2],
        [0,2,0,2],
      ],
      [
        [0,0,4,0],
        [3,0,5,0],
        [0,2,0,6],
        [0,0,1,0],
      ],
      [
        [0,4,0,1],
        [0,0,0,0],
        [4,0,2,0],
        [0,0,0,0],
      ],
      [
        [0,0,2,0],
        [0,0,0,5],
        [6,3,5,0],
        [1,0,0,0],
      ],
      [
        [0,1,0,0],
        [0,0,3,0],
        [5,0,0,3],
        [0,0,0,0],
      ]
    ],
    // prettier-ignore
    successOrder: [
        [2,1,2,1],
        [3,5,3,5],
        [5,3,5,3],
        [1,2,1,2],
    ],
    meta: {
      time: 180,
      rotations: 20,
      removals: 8,
    },
  },
  {
    doop: 30,
    text: '-',
    challenge: challengeTypes.hard,
    // prettier-ignore
    gameBlocks: [
      [
        [1,3,0,0],
        [1,0,0,0],
        [2,0,5,0],
        [6,0,0,0],
      ],
      [
        [0,1,2,0],
        [5,0,0,0],
        [0,4,0,0],
        [1,0,3,0],
      ],
      [
        [0,2,0,3],
        [2,0,2,4],
        [0,2,0,0],
        [4,0,0,0],
      ],
      [
        [0,0,4,6],
        [0,2,0,0],
        [0,0,0,0],
        [0,1,2,4],
      ],
      [
        [0,0,0,4],
        [4,1,0,1],
        [0,0,3,2],
        [0,3,0,0],
      ],
      [
        [0,0,3,0],
        [0,3,0,0],
        [1,0,0,4],
        [0,0,0,1],
      ]
    ],
    // prettier-ignore
    successOrder: [
      [1,2,3,4],
      [4,3,2,1],
      [1,2,3,4],
      [4,3,2,1],
    ],
    meta: {
      time: 180,
      rotations: 20,
      removals: 8,
    },
  },
];
