import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CloseHelpStyled from './CloseHelp.styled';
import { showHelpOverlay } from '../../../state/actions/showHelpOverlay';
import IconX from '../icons/IconX/IconX';

const mapStateToProps = state => ({
  helpOpen: state.app.help.open,
  level: state.saved.current,
});

const mapDispatchToProps = dispatch => ({
  showHelpOverlay: obj => dispatch(showHelpOverlay(obj)),
});

const handleHelpToggle = (helpOpen, showHelpOverlay, level) => {
  showHelpOverlay({ open: !helpOpen, level });
};

const CloseHelp = ({ helpOpen, showHelpOverlay, title, level }) => (
  <CloseHelpStyled
    title={title}
    onClick={() => handleHelpToggle(helpOpen, showHelpOverlay, level)}
  >
    <IconX viewBox="-3 -5 30 30" sizeWH={16} title={title} />
  </CloseHelpStyled>
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CloseHelp);

CloseHelp.propTypes = {
  level: PropTypes.number.isRequired,
  showHelpOverlay: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  helpOpen: PropTypes.bool.isRequired,
};
