import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReturnButtonStyled from './ReturnButton.styled';
import { toggleViewGame } from '../../../state/actions/toggleViewGame';
import IconReturn from '../icons/IconReturn/IconReturn';

const mapDispatchToProps = dispatch => ({
  toggleViewGame: int => dispatch(toggleViewGame(int)),
});

const ReturnButton = ({ toggleViewGame }) => (
  <ReturnButtonStyled onClick={() => toggleViewGame(false)}>
    <IconReturn title="Return to Game Select" />
  </ReturnButtonStyled>
);

export default connect(
  null,
  mapDispatchToProps
)(ReturnButton);

ReturnButton.propTypes = {
  toggleViewGame: PropTypes.func.isRequired,
};
