import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import {
  MainGameBlock,
  RemoveLayer,
  RotateLayer,
  TransitionLayer,
} from './GameLayers.styled';
import {
  transitionDuration,
  layerText,
  terms,
} from '../../../utils/constants/constants';
import IconX from '../../1-atoms/icons/IconX/IconX';
import IconRotate from '../../1-atoms/icons/IconRotate/IconRotate';
import IconLock from '../../1-atoms/icons/IconLock/IconLock';
import {
  GameLayerCol,
  GameLayerRow,
} from '../../1-atoms/GoalBlock/GoalBlock.styled';

const GameLayers = ({
  gameLayers,
  removeLayer,
  rotateLayer,
  gameOptionsInUse,
  layersCount,
  buttonsDisabled,
  win,
  fail,
  rotateBlocksActive,
}) => (
  <TransitionGroup component={null}>
    {gameLayers.map(({ bID, row }, bIndex) => (
      <CSSTransition key={bID} timeout={transitionDuration} classNames="fade">
        <TransitionLayer key={bID}>
          {bIndex === layersCount && bIndex > 0 && !win && (
            <Fragment>
              <RemoveLayer
                data-remove-layer
                disabled={buttonsDisabled}
                onClick={() =>
                  removeLayer({
                    optionBlockIndex: bIndex,
                    mainBlockIndex: gameOptionsInUse.indexOf(bIndex),
                  })
                }
              >
                <IconX
                  title={layerText.remove}
                  sizeWH={14}
                  viewBox="-3 -4 30 30"
                />
              </RemoveLayer>
              <RotateLayer
                data-rotate-layer
                onClick={e =>
                  rotateLayer({
                    e,
                    mainBlockIndex: bIndex,
                    optionBlockIndex: [...gameOptionsInUse].pop(),
                  })
                }
              >
                <IconRotate
                  title={layerText.rotate}
                  disabled={buttonsDisabled}
                  viewBox="0 0 25 25"
                  sizeWH={15}
                  className={bIndex === rotateBlocksActive.main && 'rotate'}
                />
              </RotateLayer>
            </Fragment>
          )}
          {layersCount === 0 && (
            <IconLock
              viewBox="-4 -3 32 32"
              sizeWH={20}
              main
              title={layerText.locked}
            />
          )}
          <MainGameBlock
            data-main-block={bIndex}
            title={
              bIndex === 0
                ? `${terms.stack}: Layer #1 (locked)`
                : `${terms.stack}: Layer #${bIndex} (click to rotate)`
            }
            disabled={buttonsDisabled}
            className={`${bIndex === rotateBlocksActive.main &&
              'rotate'} ${win && 'win'} ${fail && 'fail'}`}
            onClick={e =>
              rotateLayer({
                e,
                mainBlockIndex: bIndex,
                optionBlockIndex: [...gameOptionsInUse].pop(),
              })
            }
            layerNumber={bIndex}
          >
            {row.map(([{ rID, col }]) => (
              <GameLayerRow key={rID}>
                {col.map(({ cID, type }) => (
                  <GameLayerCol mainBlockNum={bIndex} key={cID} type={type} />
                ))}
              </GameLayerRow>
            ))}
          </MainGameBlock>
        </TransitionLayer>
      </CSSTransition>
    ))}
  </TransitionGroup>
);

export default GameLayers;

GameLayers.propTypes = {
  gameLayers: PropTypes.arrayOf(PropTypes.object).isRequired,
  rotateLayer: PropTypes.func.isRequired,
  removeLayer: PropTypes.func.isRequired,
  layersCount: PropTypes.number.isRequired,
  buttonsDisabled: PropTypes.bool.isRequired,
  win: PropTypes.bool.isRequired,
  fail: PropTypes.bool.isRequired,
  gameOptionsInUse: PropTypes.arrayOf(PropTypes.number).isRequired,
  rotateBlocksActive: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
};
