import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import { connect } from 'react-redux';
import HeaderStyled, { Link, Home, LogoWrap } from './Header.styled';
import { loadDelay } from '../../../utils/constants/constants';
import ReturnButton from '../../1-atoms/ReturnButton/ReturnButton';
import BurgerNav from '../../3-organisms/BurgerNav/BurgerNav';
import GameHeader from '../GameHeader/GameHeader';
import OpenHelp from '../../1-atoms/OpenHelp/OpenHelp';
import Logo from '../../1-atoms/Logo/Logo';
import { selectionChange } from '../../../state/actions/selectionChange';

const mapStateToProps = state => ({
  location: state.router.location.pathname || '',
  game: state.app.viewing.game,
  levelNo: state.saved.current,
});

const mapDispatchToProps = dispatch => ({
  selectionChange: obj => dispatch(selectionChange(obj)),
});

const Header = ({ location, game, levelNo, selectionChange }) => (
  <CSSTransition classNames="header" appear in timeout={loadDelay}>
    <HeaderStyled>
      {location === '/' && game ? (
        <LogoWrap>
          <Logo title="Doop" />
        </LogoWrap>
      ) : location === '/' && !game ? (
        <Home
          tabIndex={0}
          onClick={() => selectionChange({ current: 0, dir: 'left' })}
          title="Return"
          data-logo-btn
        >
          <Logo title="Doop" />
        </Home>
      ) : (
        <Link
          tabIndex={0}
          to="/"
          exact
          activeClassName="active"
          title="Home"
          data-logo-btn
        >
          <Logo title="Doop" />
        </Link>
      )}
      {game && <GameHeader levelNo={levelNo} />}
      {game && <ReturnButton />}
      {game && <OpenHelp />}
      {!game && <BurgerNav />}
    </HeaderStyled>
  </CSSTransition>
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);

Header.propTypes = {
  selectionChange: PropTypes.func.isRequired,
  game: PropTypes.bool.isRequired,
  location: PropTypes.string.isRequired,
  levelNo: PropTypes.number.isRequired,
};
