import styled from 'styled-components';
import OpenHelpStyled from '../OpenHelp/OpenHelp.styled';

const CloseHelpStyled = styled(OpenHelpStyled)`
  background: ${props => props.theme.colors.copy};
  border-radius: 100%;
  color: ${props => props.theme.colors.copyAlt};
  padding: 15px;

  svg {
    fill: ${props => props.theme.colors.bg};
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-48%, -56%);
  }

  &:hover {
    svg {
      fill: ${props => props.theme.colors.copy};
    }
    &::before {
      background: ${props => props.theme.colors.bg};
    }
  }
`;

export default CloseHelpStyled;
