import styled, {css} from 'styled-components';
import {
  rotate90Transition,
  sideText,
} from '../../../utils/helpers/transitions';
import { RemoveLayer } from '../GameLayers/GameLayers.styled';
import { terms } from '../../../utils/constants/constants';

export const RemoveOption = styled(RemoveLayer)`
  background: transparent;
  position: relative;
  right: initial;
  top: initial;
`;

export const Details = styled.section`
  bottom: -20px;
  display: flex;
  height: 15px;
  justify-content: space-between;
  left: 15px;
  position: absolute;
  right: 10px;

  button {
    right: -5px;
    top: -7px;
  }
`;

export const OrderNum = styled.span`
  color: ${props => props.theme.colors.bg};
  font-size: calc(${props => props.theme.fonts.baseSize} - 3px);
  font-family: ${props => props.theme.fonts.primary};
  font-weight: 700;
  line-height: 1;
  position: relative;
`;

export const Block = styled.button`
  background: ${props =>
    props.inUse ? props.theme.blockOption.inUse : props.theme.blockOption.bg};
  border: none;
  ${rotate90Transition};
  border-radius: 5px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  margin: 5px;
  padding: 5px;
  position: relative;
  transition: background ${props => props.theme.transition.duration}
    ${props => props.theme.transition.easeOut};

  &::before {
    border-radius: 5px;
    bottom: -1px;
    box-shadow: 0 0 0 ${props => props.theme.colors.bg};
    content: '';
    left: -1px;
    opacity: 0;
    position: absolute;
    right: -1px;
    top: -1px;
    transform: scale(0.8);
    transition: opacity ${props => props.theme.transition.duration}
        ${props => props.theme.transition.easeOut},
      transform ${props => props.theme.transition.duration}
        ${props => props.theme.transition.cubicBez};
  }

  &:hover {
    background: ${props =>
      props.inUse && props.disabled
        ? props.theme.blockOption.inUse
        : props.inUse
        ? props.theme.blockOption.inUseHover
        : props.theme.blockOption.inUse};
  }
  &:focus {
    outline: 0;

    &::before {
      box-shadow: 0 0 0 3px ${props => props.theme.colors.bg};
      opacity: 1;
      transform: scale(1);
    }
  }
`;

const optionsButtonSize = '40px';
const optionsButtonOffset = '-60px';
const optionsButtonOffsetXS = '-66px';
const optionsButtonOffsetXS2 = '-75px';
const optionsButtonOffsetSM = '-78px';
const optionsButtonOffsetMD = '-90px';

export const GameOptionsButton = styled.button`
  background: ${props => props.theme.colors.bg};
  border-radius: ${props => (props.left ? '0 40px 40px 0' : '40px 0 0 40px')};
  border: 0;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  height: ${optionsButtonSize};
  left: ${props => (props.left ? optionsButtonOffset : 'inherit')};
  position: absolute;
  right: ${props => (props.right ? optionsButtonOffset : 'inherit')};
  top: 50%;
  transform: translateY(-50%);
  transition: background ${props => props.theme.transition.duration} ${props => props.theme.transition.easeOut};
  width: ${optionsButtonSize};

  @media (min-width: ${props => props.theme.breakpoints.xs}) {
    left: ${props => (props.left ? optionsButtonOffsetXS : 'inherit')};
    right: ${props => (props.right ? optionsButtonOffsetXS : 'inherit')};
  }

  @media (min-width: ${props => props.theme.breakpoints.xs2}) {
    left: ${props => (props.left ? optionsButtonOffsetXS2 : 'inherit')};
    right: ${props => (props.right ? optionsButtonOffsetXS2 : 'inherit')};
  }

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    left: ${props => (props.left ? optionsButtonOffsetSM : 'inherit')};
    right: ${props => (props.right ? optionsButtonOffsetSM : 'inherit')};
  }

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    left: ${props => (props.left ? optionsButtonOffsetMD : 'inherit')};
    right: ${props => (props.right ? optionsButtonOffsetMD : 'inherit')};
  }

  &:disabled,
  &:disabled:hover,
  &:disabled:focus {
    background: ${props => props.theme.colors.grey};

    svg {
      fill: ${props => props.theme.colors.disabled};
    }
  }

  &:hover,
  &:focus {
    background: ${props => props.theme.colors.icon};
    outline: 0;

    svg {
      fill: ${props => props.theme.colors.bg};
    }
  }

  svg {
    fill: ${props => props.theme.colors.copy};
  }
`;

export const GameOptionsCarousel = styled.section`
  overflow: hidden;
`;

export const GameOptionsRow = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
  padding-bottom: 30px;
  transform: translateX(-${props => props.position / props.total}%);
  transition: transform ${props => props.theme.transition.duration}
    ${props => props.theme.transition.cubicBez};
  width: ${props => props.width}%;
`;

export const GameOptionsItem = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const GameBlock = styled.section`
  margin: 0;
  padding: 0;
  position: relative;
`;

const gameBlocksMoveUp = css`
  top: ${props => props.theme.game.moveUp};
`;

const GameBlocks = styled.section`
  ${props => !props.carousel ? gameBlocksMoveUp : 'top: 20px'};
  display: flex;
  position: relative;
  width: ${props => (props.carousel ? '69%' : 'initial')};

  @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
    width: ${props => (props.carousel ? '62%' : 'initial')};
  }

  &::before {
    ${sideText};
    content: '${terms.options}';
    font-size: 9px;
    left: -27px;
    top: 23px;

    @media screen and (min-width: ${props => props.theme.breakpoints.sm}) {
      left: -35px;
    }

    @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
      top: 23px;
    }
  }
`;

export default GameBlocks;
