export const PAGE_LOADED = 'PAGE_LOADED';
export const TOGGLE_MENU_OPEN = 'TOGGLE_MENU_OPEN';
export const SET_HELP_VIEWED = 'SET_HELP_VIEWED';
export const SHOW_HELP_OVERLAY = 'SHOW_HELP_OVERLAY';
export const SAVE_SCORES = 'SAVE_SCORES';
export const SELECTION_CHANGE = 'SELECTION_CHANGE';
export const TOGGLE_VIEW_GAME = 'TOGGLE_VIEW_GAME';
export const CHANGE_HELP_POSITION = 'CHANGE_HELP_POSITION';
export const CHANGE_HELP_STAGE = 'CHANGE_HELP_STAGE';
export const SET_STARS_EARNED = 'SET_STARS_EARNED';
export const TOGGLE_ENDGAME = 'TOGGLE_ENDGAME';
