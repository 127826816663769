import styled from 'styled-components';
import { sideText } from '../../../utils/helpers/transitions';
import { terms } from '../../../utils/constants/constants';

export const GameDirection = styled.section`
  border-left: 0;
  border-right: 0;
  padding: 0;
  position: relative;
  top: -${props => props.theme.game.moveUp};
`;

export const GameLayerStyled = styled.section`
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
`;

export const GameLayersStyled = styled.section`
  opacity: ${props => (props.hide ? 0 : 'inherit')};
  padding: ${props => props.theme.game.padding};
  position: relative;
  transition: opacity ${props => props.theme.transition.duration} ${props =>
  props.theme.transition.easeOut};

  &::before {
    ${sideText};
    content: '${terms.stack}';
    left: -7px;
    top: 35px;
  }
`;

export const Content = styled.section`
  bottom: 0;
  height: 100%;
  left: 0;
  opacity: 0;
  position: relative;
  right: 0;
  top: 0;
  transform: translateY(20px);
  transition: opacity ${props => props.theme.transition.duration}
    ${props => props.theme.transition.easeOut};
  width: 100%;

  .game-enter-done &,
  .select-game-enter-done & {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const GameTransition = styled.section`
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`;

export const Row = styled.section`
  display: block;
  height: calc(
    ${props => props.theme.game.block.size} +
      ${props => props.theme.game.block.marginCalc * 2}px
  );
  text-align: center;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    height: calc(
      ${props => props.theme.game.block.size} + 5px +
        ${props => props.theme.game.block.marginCalc * 4}px
    );
  }
`;

export const GameWrap = styled.section`
  align-items: center;
  bottom: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  left: 0;
  overflow: hidden;
  opacity: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: transform 0.3s ${props => props.theme.transition.cubicBez},
    opacity 0.3s ${props => props.theme.transition.cubicBez};

  .ready-appear &,
  .ready-enter & {
    opacity: 0;
    transform: translateY(50px);
  }

  .ready-enter-done & {
    opacity: 1;
    transform: translateY(0) scale(1);
  }

  .ready-exit & {
    opacity: 1;
    transform: translateX(0);
  }

  .ready-exit-active & {
    opacity: 0;
    transform: translateX(50px);
  }
`;

export const GameWrapInner = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  transform: scale(${props => (props.memorising ? 0.85 : 1)});
  transition: transform ${props => props.theme.transition.duration}
    ${props => props.theme.transition.easeOut};
`;

const GameStyled = styled.section`
  align-items: center;
  background: ${props => props.theme.game.bg};
  border-radius: 20px 20px 0 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  transform: translateY(10px);
  top: 10px;
  transition: transform ${props => props.theme.transition.duration}
    ${props => props.theme.transition.cubicBez};

  .game-enter-done & {
    opacity: 1;
    transform: translateY(0);
  }

  @media screen and (min-height: ${props =>
      props.theme.breakpoints.height.md}) {
    bottom: initial;
    height: ${props => (props.blockCount > 9 ? '565px' : '520px')};
    position: absolute;
    top: 50%;
    transform: translateY(-40%);

    .game-enter-done & {
      opacity: 1;
      transform: translateY(-50%);
    }
  }

  @media screen and (min-width: ${props =>
      props.theme.breakpoints.xs}) and (max-height: ${props =>
      props.theme.breakpoints.height.sm}) {
    border-radius: 30px 30px 0 0;
  }

  @media screen and (min-width: ${props =>
      props.theme.breakpoints.xs}) and (min-height: ${props =>
      props.theme.breakpoints.height.md}) {
    border-radius: 30px;
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.sm}) {
    left: 50%;
    right: initial;
    top: 50px;
    transform: translateX(-50%);
    width: ${props => (props.blockCount > 9 ? '400px' : '380px')};

    .game-enter-done & {
      opacity: 1;
      transform: translateX(-50%);
    }
  }

  @media screen and (min-width: ${props =>
      props.theme.breakpoints.sm}) and (min-height: ${props =>
      props.theme.breakpoints.height.sm}) {
    height: ${props => (props.blockCount > 9 ? '590px' : '520px')};
    left: 50%;
    top: 50%;
    transform: translate(-50%, -40%);
    width: ${props => (props.blockCount > 9 ? '460px' : '380px')};

    .game-enter-done & {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
  }

  @media screen and (min-width: ${props =>
      props.theme.breakpoints.md}) and (min-height: ${props =>
      props.theme.breakpoints.height.lg}) {
    height: ${props => (props.blockCount > 9 ? '640px' : '520px')};
    left: 50%;
    top: 50%;
    transform: translate(-50%, -40%);
    width: ${props => (props.blockCount > 9 ? '460px' : '380px')};

    .game-enter-done & {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
  }
`;

export default GameStyled;
