import React from 'react';
import PropTypes from 'prop-types';
import EndGameMeaniesStyled from './EndGameMeanies.styled';
import IconMeanie from '../../1-atoms/icons/IconMeanie/IconMeanie';

const meanieSize = 40;

const EndGameMeanies = ({ help = false }) => (
  <EndGameMeaniesStyled help={help}>
    <IconMeanie delay={2} sizeWH={meanieSize} title="Meanie" />
    <IconMeanie delay={2.2} raise sizeWH={meanieSize} title="Meanie" />
    <IconMeanie delay={2.4} sizeWH={meanieSize} title="Meanie" />
  </EndGameMeaniesStyled>
);

export default EndGameMeanies;

EndGameMeanies.propTypes = {
  help: PropTypes.bool,
};

EndGameMeanies.defaultProps = {
  help: false,
};
