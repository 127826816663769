import React, { Component } from 'react';
import PropTypes from 'prop-types';

const { gtag } = window;

const GATracker = (WrappedComponent, options = {}) => {
  const trackPage = page => {
    gtag('event', 'page_view', { page_path: page });
  };

  const HOC = class extends Component {
    componentDidMount() {
      const page = this.props.location.pathname;
      trackPage(page);
    }

    componentWillReceiveProps(nextProps) {
      const currentPage = this.props.location.pathname;
      const nextPage = nextProps.location.pathname;

      if (currentPage !== nextPage) {
        trackPage(nextPage);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  return HOC;
};

GATracker.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default GATracker;
