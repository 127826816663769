import styled from 'styled-components';

const Title = styled.h1`
  letter-spacing: 2px;
  color: ${props =>
    props.type === 'dark' ? props.theme.colors.bg : props.type === 'black' ? props.theme.colors.copyAlt : props.theme.colors.copy};
  font-size: ${props => props.large ? `calc(${props => props.theme.fonts.baseSize} + 10vmin)` : `calc(${props => props.theme.fonts.baseSize} + 0.5vmin)`};
  font-family: ${props => props.theme.fonts.primary};
  line-height: 1;
  position: relative;
  text-transform: uppercase;
`;

export default Title;
