import styled, { css } from 'styled-components';
import { pulseTiny } from '../../../utils/helpers/animations';
import { endgame, terms } from '../../../utils/constants/constants';
import { sideText } from '../../../utils/helpers/transitions';

export const GameLayerCol = styled.div`
  background: ${props =>
    props.type === 0 && props.mainBlockNum === 0 && !props.thumb
      ? props.theme.game.block.color.empty
      : props.type === 'd' && props.mainBlockNum === 0 && !props.thumb
      ? props.theme.game.block.color.demoEmpty
      : props.type === 'd' && props.mainBlockNum !== 0 && !props.thumb
      ? props.theme.game.block.color.trans
      : props.type === 'd' && props.thumb
      ? props.theme.game.block.color.demoEmpty
      : props.type === 0 && !props.thumb
      ? props.theme.game.block.color.trans
      : props.type === 1
      ? props.theme.game.block.color.c1
      : props.type === 2
      ? props.theme.game.block.color.c2
      : props.type === 3
      ? props.theme.game.block.color.c3
      : props.type === 4
      ? props.theme.game.block.color.c4
      : props.type === 5
      ? props.theme.game.block.color.c5
      : props.type === 6
      ? props.theme.game.block.color.c6
      : props.type === 7
      ? props.theme.game.block.color.c7
      : props.type === 8
      ? props.theme.game.block.color.c8
      : props.type === 9
      ? props.theme.game.block.color.c0
      : props.theme.game.block.color.empty};
  border-radius: 100%;
  height: ${props =>
    props.goal
      ? props.theme.game.block.size.goal
      : props.thumb
      ? props.theme.game.block.size.thumb
      : props.theme.game.block.size.main};
  margin: ${props =>
    props.thumb
      ? props.theme.game.block.margin.thumb
      : props.goal
      ? props.theme.game.block.margin.goal
      : props.theme.game.block.margin.main};
  position: relative;
  width: ${props =>
    props.goal
      ? props.theme.game.block.size.goal
      : props.thumb
      ? props.theme.game.block.size.thumb
      : props.theme.game.block.size.main};

  @media screen and (min-width: ${props => props.theme.breakpoints.xs}) {
    height: ${props =>
      props.goal
        ? props.theme.game.block.sizeMD.goal
        : props.thumb
        ? props.theme.game.block.sizeMD.thumb
        : props.theme.game.block.sizeMD.main};
    margin: ${props =>
      props.thumb
        ? props.theme.game.block.margin.thumb
        : props.goal
        ? props.theme.game.block.margin.goal
        : props.theme.game.block.margin.main};
    width: ${props =>
      props.goal
        ? props.theme.game.block.sizeMD.goal
        : props.thumb
        ? props.theme.game.block.sizeMD.thumb
        : props.theme.game.block.sizeMD.main};
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
    height: ${props =>
      props.goal
        ? props.theme.game.block.sizeLG.goal
        : props.thumb
        ? props.theme.game.block.sizeLG.thumb
        : props.theme.game.block.sizeLG.main};
    margin: ${props =>
      props.thumb
        ? props.theme.game.block.marginLG.thumb
        : props.goal
        ? props.theme.game.block.marginLG.goal
        : props.theme.game.block.marginLG.main};
    width: ${props =>
      props.goal
        ? props.theme.game.block.sizeLG.goal
        : props.thumb
        ? props.theme.game.block.sizeLG.thumb
        : props.theme.game.block.sizeLG.main};
  }

  &::before {
    background: ${props => props.theme.game.block.color.dot};
    border-radius: 100%;
    content: '';
    display: ${props => (props.type === 4 ? '' : 'none')};
    height: ${props => (props.thumb ? '2px' : props.goal ? '3px' : '5px')};
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: ${props => (props.thumb ? '2px' : props.goal ? '3px' : '5px')};
    z-index: 5;
  }
`;

export const GameLayerRow = styled.div`
  display: flex;
  opacity: 1;
`;

const showPulse = css`
  animation: ${pulseTiny} ${endgame.pulseTime};
`;

const GoalBlockStyled = styled.section`
  ${props => props.win && showPulse};
  border-radius: 3px;
  border: 2px dashed
    ${props => (props.dark ? props.theme.colors.bg : props.theme.colors.copy)};
  margin: 0 10px 45px;
  padding: 5px;
  position: relative;
  top: -${props => props.theme.game.moveUp};

  &::before {
    ${sideText};
    content: '${terms.goal}';
    
  }
`;

export default GoalBlockStyled;
