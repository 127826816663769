import styled from 'styled-components';

const paddingWidth = '20vw';

const FullPageCenteredStyled = styled.section`
  align-items: center;
  display: flex;
  color: ${props => props.theme.colors.copyAlt};
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  margin: 0 auto;
  padding: 1vmin;
  position: relative;
  text-align: center;
  width: calc(100vw - ${paddingWidth});

  svg {
    margin-bottom: 10px;
  }

  p {
    font-family: ${props => props.theme.fonts.primary};
    font-size: calc(${props => props.theme.fonts.baseSize} - 0.5vmin);
    margin-top: 0;

    @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
      font-size: calc(${props => props.theme.fonts.baseSize} + 0.1vmin);
    }
  }
`;

export default FullPageCenteredStyled;
