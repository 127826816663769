import * as types from '../constants/stateConstants';

export const savedReducer = (state = {}, action) => {
  switch (action.type) {
    case types.SELECTION_CHANGE:
      return {
        ...state,
        current: action.payload.current,
        dir: action.payload.dir,
      };
    case types.SAVE_SCORES:
      const scoresUpdated = [...state.scores];
      scoresUpdated[action.payload.level] = action.payload.data;

      return {
        ...state,
        scores: scoresUpdated,
        starsEarned: scoresUpdated.reduce(
          (acc, curr) => acc + curr.starRating,
          0
        ),
        levels: {
          ...state.levels,
          progress:
            action.payload.level + 1 > state.levels.progress
              ? action.payload.level + 1
              : state.levels.progress,
        },
      };
    default:
      return state;
  }
};
