import React from 'react';
import { CSSTransition } from 'react-transition-group';
import RoutesTransition from '../../4-templates/Routes/Routes.styled';
import LoaderStyled from './Loading.styled';
import { loadDelay } from '../../../utils/constants/constants';
import LoadingSmall from '../LoadingSmall/LoadingSmall';

const Loading = () => (
  <CSSTransition classNames="fade" timeout={loadDelay}>
    <RoutesTransition>
      <LoaderStyled aria-label="Loading" className="fade">
        <LoadingSmall />
      </LoaderStyled>
    </RoutesTransition>
  </CSSTransition>
);

export default Loading;
