import styled from 'styled-components';

const titleWH = '30px';

export const Title = styled.h1`
  border-radius: 100%;
  background: ${props => props.theme.colors.icon};
  color: ${props => props.theme.colors.bg};
  font-size: calc(${props => props.theme.fonts.baseSize} - 1px);
  font-weight: 700;
  height: ${titleWH};
  line-height: ${titleWH};
  margin: 0 auto;
  position: relative;
  text-transform: uppercase;
  top: -10px;
  width: ${titleWH};

  @media screen and (max-width: 375px) and (max-height: 680px) {
    left: 75%;
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 30px;
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
    top: -3px;
  }

  @media (min-height: ${props => props.theme.breakpoints.height.lg}) {
    top: 10px;
  }
`;
