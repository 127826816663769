import React from 'react';
import PropTypes from 'prop-types';
import IconRotateStyled from './IconRotate.styled';

const IconRotate = props => (
  <IconRotateStyled
    width={props.sizeWH}
    height={props.sizeWH}
    viewBox={props.viewBox}
    {...props}
  >
    <title>{props.title}</title>
    <path d="M18.312 5.595l1.296-1.527a11.07 11.07 0 0 1 1.869 2.369l-1.776.931a8.956 8.956 0 0 0-1.389-1.773zm3.844 2.179l-1.787.937c.255.647.435 1.33.533 2.039h2.021a10.887 10.887 0 0 0-.767-2.976zm-14.81-3.46A8.891 8.891 0 0 1 12 3c1.919 0 3.695.608 5.157 1.636l1.298-1.529A10.937 10.937 0 0 0 12 1a10.9 10.9 0 0 0-5.789 1.673L4.361 0 2.209 7h6.994L7.346 4.314zm13.642 7.936a8.927 8.927 0 0 1-.514 2.75h2.101c.249-.878.401-1.798.425-2.75h-2.012zm-15.3 6.155l-1.295 1.527a11.04 11.04 0 0 1-1.869-2.369l1.775-.931c.39.648.853 1.246 1.389 1.773zm-3.844-2.179l1.787-.937a8.969 8.969 0 0 1-.533-2.039H1.076c.119 1.043.379 2.042.768 2.976zm14.81 3.46A8.886 8.886 0 0 1 12 21a8.93 8.93 0 0 1-5.156-1.636l-1.299 1.529a10.898 10.898 0 0 0 12.244.434L19.639 24l2.152-7h-6.994l1.857 2.686zM3.012 11.75A8.985 8.985 0 0 1 3.525 9h-2.1A11.042 11.042 0 0 0 1 11.75h2.012z" />
  </IconRotateStyled>
);

export default IconRotate;

IconRotate.propTypes = {
  title: PropTypes.string.isRequired,
  sizeWH: PropTypes.number,
  viewBox: PropTypes.string,
};

IconRotate.defaultProps = {
  sizeWH: 24,
  viewBox: '0 0 30 30',
};
