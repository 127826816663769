import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import ShareResultStyled, {
  ResultText,
  Links,
  Twitter,
  Facebook,
  TextWrap,
  ShareButtons,
} from './ShareResultStyled';
import {
  transitionDuration,
  siteURL,
} from '../../../utils/constants/constants';
import IconFacebook from '../../1-atoms/icons/IconFacebook/IconFacebook';
import IconTwitter from '../../1-atoms/icons/IconTwitter/IconTwitter';

const mapStateToProps = state => ({
  endgame: state.app.endgame,
  results: state.app.lastScore || {},
});

const ShareResult = ({ endgame, results }) => {
  const { starRating, timeTaken, current } = results;

  const resultText = starRating => {
    switch (starRating) {
      case 3:
        return 'A perfect score!';
      case 2:
        return 'Nicely done!';
      default:
        return 'You did it!';
    }
  };

  return (
    <CSSTransition
      classNames="share"
      appear={endgame}
      in={endgame}
      timeout={transitionDuration * 4}
    >
      <ShareResultStyled aria-hidden={!endgame}>
        <TextWrap>
          <ResultText>{resultText(starRating)}</ResultText>
          <ShareButtons>
            <Links>
              <Twitter
                href={`https://twitter.com/intent/tweet?url=https%3A%2F%2Fdoopgame.com&text=I%20just%20solved%20Doop%20level%3A%20${current}%20in%20a%20mere%20${timeTaken}%20seconds.%20Think%20you%20can%20beat%20that%3F%20&hashtags=DoopGame`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconTwitter title="Share on Twitter" invert sizeWH={15} />
              </Twitter>
              <Facebook
                href={`https://www.facebook.com/sharer.php?u=${siteURL}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconFacebook title="Share on Facebook" invert sizeWH={15} />
              </Facebook>
            </Links>
          </ShareButtons>
        </TextWrap>
      </ShareResultStyled>
    </CSSTransition>
  );
};

export default connect(mapStateToProps)(ShareResult);

ShareResult.propTypes = {
  results: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
  endgame: PropTypes.bool.isRequired,
};
