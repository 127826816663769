import styled from 'styled-components';

export const TextWrap = styled.p`
  align-items: center;
  color: ${props => props.theme.colors.copyAlt};
  display: flex;
  font-size: ${props => props.theme.fonts.baseSize};
  justify-content: center;
  margin: 0;
  position: relative;
`;

export const ShareButtons = styled.span`
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 10px;
  justify-content: center;
`;

export const ResultText = styled.strong`
  font-size: calc(${props => props.theme.fonts.baseSize} + 3px);
  position: relative;
  font-weight: bold;
  margin-right: 15px;
`;

export const Links = styled.span`
  display: flex;
`;

const WH = '30px';

export const Twitter = styled.a`
  background: ${props => props.theme.colors.icon};
  border-radius: 50px;
  display: inline-block;
  height: ${WH};
  position: relative;
  transition: background ${props => props.theme.transition.duration}
    ${props => props.theme.transition.easeOut};
  width: ${WH};

  svg {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover {
    background: ${props => props.theme.colors.bg};

    svg {
      fill: ${props => props.theme.colors.icon};
    }
  }

  &:focus {
    outline: 0;
    &::before {
      border-radius: 100%;
      box-shadow: 0 0 0 3px ${props => props.theme.colors.bg};
      content: '';
      height: ${WH};
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: ${WH};
    }
  }
`;

export const Facebook = styled(Twitter)`
  margin-left: 8px;
`;

const ShareResult = styled.section`
  background: ${props => props.theme.colors.copy};
  border-radius: 100px;
  box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.1);
  left: 50%;
  max-width: 250px;
  opacity: 0;
  padding: 10px 20px;
  position: fixed;
  top: 15px;
  transform: translate(-50%, -150%);
  transition: opacity ${props => props.theme.transition.duration}
      ${props => props.theme.transition.easeOut},
    transform ${props => props.theme.transition.duration}
      ${props => props.theme.transition.easeOut};
  width: 100%;
  z-index: ${props => props.theme.zIndex.overlay};

  &.share-enter-done {
    opacity: 1;
    transform: translate(-50%, 0);
  }
`;

export default ShareResult;
