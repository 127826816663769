import React from 'react';
import PropTypes from 'prop-types';
import StyledLogo from './Logo.styled';

const Logo = props => {
  return (
    <StyledLogo
      {...props}
      width={props.width}
      height={props.height}
      viewBox={props.viewBox}
    >
      <path d="M183 55c-27.6 0-50 22.4-50 50s22.4 50 50 50 50-22.4 50-50-22.4-50-50-50zm0 73c-12.7 0-23-10.3-23-23s10.3-23 23-23 23 10.3 23 23-10.3 23-23 23zM299 55c-27.6 0-50 22.4-50 50s22.4 50 50 50 50-22.4 50-50-22.4-50-50-50zm0 73c-12.7 0-23-10.3-23-23s10.3-23 23-23 23 10.3 23 23-10.3 23-23 23zM90 27v31.6C83.1 55 75.3 53 67 53c-27.6 0-50 22.4-50 50s22.4 50 50 50 50-22.4 50-50V27c0-17.4-27-17.4-27 0zm-23 99c-12.7 0-23-10.3-23-23s10.3-23 23-23 23 10.3 23 23-10.3 23-23 23zM415 53c-27.6 0-50 22.4-50 50v76c0 17.4 27 17.4 27 0v-31.6c6.9 3.6 14.7 5.6 23 5.6 27.6 0 50-22.4 50-50s-22.4-50-50-50zm0 73c-12.7 0-23-10.3-23-23s10.3-23 23-23 23 10.3 23 23-10.3 23-23 23z" />
    </StyledLogo>
  );
};

export default Logo;

Logo.propTypes = {
  title: PropTypes.string,
  viewBox: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

Logo.defaultProps = {
  title: 'Doop',
  viewBox: '0 0 482 206',
  height: 30,
  width: 85,
};
