import { keyframes } from 'styled-components';

export const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const rotate90Staggered = keyframes`
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const layerInOut = keyframes`
  0%, 100% {
    transform: scale(1.5);
    opacity: 0;
  }
  40%, 60% {
    transform: scale(1);
    opacity: 1;
  }
`;

export const rotateCenterd = keyframes`
  from {
    transform: rotate(0deg) translate(-50%, -50%);
  }
  to {
    transform: rotate(360deg) translate(-50%, -50%);
  }
`;

export const pulseTiny = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
  40% {
    box-shadow: 0 0 0 10px #ffed38;
  }
  100% {
    box-shadow: 0 0 0 30px rgba(255, 255, 255, 0);
  }
`;

export const pulseSmall = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
  40% {
    box-shadow: 0 0 0 20px #ffed38;
  }
  100% {
    box-shadow: 0 0 0 50px rgba(255, 255, 255, 0);
  }
`;

export const pulseFailSmall = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
  }
  40% {
    box-shadow: 0 0 0 20px rgba(255, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 50px rgba(255, 0, 0, 0);
  }
`;

export const upDownCentered = keyframes`
  0%, 100% {
    transform: translate(-50%, 0);
  }
  50% {
    transform: translate(-50%, 5px);
  }
`;

export const rotate90AntiClockwiseLoop = keyframes`
  0%, 100% {
    transform: rotate(90deg);
  }
  15%, 85% {
    transform: rotate(0deg);
  }
`;
