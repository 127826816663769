import React from 'react';
import { CSSTransition } from 'react-transition-group';
import Example, {
  Layer1,
  Layer2,
  Layer3,
  Row,
  Col,
} from './ExampleAnimation.styled';
import IconTick from '../icons/IconTick/IconTick';
import { loadDelay } from '../../../utils/constants/constants';

const ExampleAnimation = props => (
  <CSSTransition classNames="example" appear in timeout={loadDelay * 2}>
    <Example {...props}>
      <IconTick color={props.color} title="Doop in action!" sizeWH={40} />
      <Layer3>
        <Row>
          <Col c2 />
          <Col />
          <Col c3 />
        </Row>
        <Row>
          <Col />
          <Col c1 />
          <Col />
        </Row>
        <Row>
          <Col />
          <Col />
          <Col c2 />
        </Row>
      </Layer3>
      <Layer2>
        <Row>
          <Col c3 />
          <Col />
          <Col />
        </Row>
        <Row>
          <Col />
          <Col />
          <Col />
        </Row>
        <Row>
          <Col />
          <Col c3 />
          <Col />
        </Row>
      </Layer2>
      <Layer1>
        <Row>
          <Col c2 />
          <Col />
          <Col />
        </Row>
        <Row>
          <Col />
          <Col />
          <Col />
        </Row>
        <Row>
          <Col />
          <Col />
          <Col c3 />
        </Row>
      </Layer1>
    </Example>
  </CSSTransition>
);

export default ExampleAnimation;
