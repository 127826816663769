import React from 'react';
import PropTypes from 'prop-types';
import IconUnhappyStyled from './IconUnhappy.styled';

const IconUnhappy = props => (
  <IconUnhappyStyled
    width={props.sizeWH}
    height={props.sizeWH}
    {...props}
    viewBox={`0 0 ${props.sizeWH / 2} ${props.sizeWH / 2}`}
  >
    <title>{props.title}</title>
    <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-3.5 8c-.828 0-1.5.671-1.5 1.5s.672 1.5 1.5 1.5 1.5-.671 1.5-1.5-.672-1.5-1.5-1.5zm7 0c-.828 0-1.5.671-1.5 1.5s.672 1.5 1.5 1.5 1.5-.671 1.5-1.5-.672-1.5-1.5-1.5zm-.941 9.94c-.903-.19-1.741-.282-2.562-.282-.819 0-1.658.092-2.562.282-1.11.233-1.944-.24-2.255-1.015-.854-2.131 1.426-3.967 4.816-3.967 3.537 0 5.666 1.853 4.817 3.968-.308.769-1.136 1.249-2.254 1.014zm-2.563-1.966c1.614 0 3.056.67 3.206.279.803-2.079-7.202-2.165-6.411 0 .138.377 1.614-.279 3.205-.279z"/>
  </IconUnhappyStyled>
);

export default IconUnhappy;

IconUnhappy.propTypes = {
  sizeWH: PropTypes.number,
  title: PropTypes.string.isRequired,
};

IconUnhappy.defaultProps = {
  sizeWH: 50,
};
