// prettier-ignore
export const targetData = [
  [6,6,6],
  [3,3,3],
  [6,6,6],
];

// prettier-ignore
export const gameData = [
  [
    ['d','d',6],
    [3,2,'d'],
    ['d',6,2],
  ],
  [
    ['d',6,'d'],
    ['d',3,2],
    [1,'d',6],
  ],
  [
    [6,'d','d'],
    ['d','d',3],
    [6,'d','d'],
  ],
];

// ===========================
// STAGE 2

// prettier-ignore
export const target2Data = [
  [6,6,6],
  [3,3,3],
  [6,6,6],
];

// prettier-ignore
export const gameData2 = [
  [
    ['d','d',6],
    [3,4,'d'],
    ['d',6,4],
  ],
  [
    ['d',6,'d'],
    ['d',3,4],
    [1,'d',6],
  ],
  [
    [6,'d','d'],
    ['d','d',3],
    [6,'d','d'],
  ],
];

// prettier-ignore
export const gameDataMeanies2 = [
  [
    [9,1,9],
    [4,4,4],
    [9,1,9],
  ],
];
