import * as actionConstants from '../../constants/stateConstants';

const { gtag } = window;

// TRACKING INFO
const trackingInformation = {
  // gets tracking information from the action  and the store
  [actionConstants.SAVE_SCORES]: ({ level, data }, store) => ({
    level,
    data,
  }),
};

// TRACKED ACTIONS
const TRACKED_ACTIONS = [actionConstants.SAVE_SCORES];

const analyticsMiddleware = store => next => action => {
  if (gtag && TRACKED_ACTIONS.includes(action.type)) {
    const payload = trackingInformation[action.type](action.payload, store);
    const { level, data } = payload;
    const { starRating, rotations, removals, timeTaken } = data;

    gtag('event', 'Level Complete', {
      event_category: 'Game Result',
      event_label: 'Level',
      value: level,
    });

    // Send Star Rating
    gtag('event', `Level ${level} Result: Star Rating`, {
      event_category: 'Game Result',
      event_label: 'Star Rating',
      value: starRating,
    });

    // Send Time Taken
    gtag('event', `Level ${level} Result: Time Taken`, {
      event_category: 'Game Result',
      event_label: 'Time Taken',
      value: timeTaken,
    });

    // Send Rotations
    gtag('event', `Level ${level} Result: Rotations`, {
      event_category: 'Game Result',
      event_label: 'Rotations',
      value: rotations,
    });

    // Send Removals
    gtag('event', `Level ${level} Result: Removals`, {
      event_category: 'Game Result',
      event_label: 'Removals',
      value: removals,
    });
  }
  return next(action);
};

export default analyticsMiddleware;
