import React from 'react';
import PropTypes from 'prop-types';
import IconMobileStyled from './IconMobile.styled';

const IconMobile = props => (
  <IconMobileStyled
    width={props.sizeWH}
    height={props.sizeWH}
    {...props}
    viewBox={props.viewBox}
  >
    <title>{props.title}</title>
    <path d="M19 2c0-1.104-.896-2-2-2h-10c-1.104 0-2 .896-2 2v20c0 1.104.896 2 2 2h10c1.104 0 2-.896 2-2v-20zm-8.5 0h3c.276 0 .5.224.5.5s-.224.5-.5.5h-3c-.276 0-.5-.224-.5-.5s.224-.5.5-.5zm1.5 20c-.553 0-1-.448-1-1s.447-1 1-1c.552 0 .999.448.999 1s-.447 1-.999 1zm5-3h-10v-14.024h10v14.024z"/>
  </IconMobileStyled>
);

export default IconMobile;

IconMobile.propTypes = {
  sizeWH: PropTypes.number,
  title: PropTypes.string.isRequired,
  viewBox: PropTypes.string,
};

IconMobile.defaultProps = {
  sizeWH: 24,
  viewBox: '0 0 24 24',
};