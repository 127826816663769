import styled from 'styled-components';
import { rotate90Transition } from '../../../utils/helpers/transitions';
import { pulseSmall, pulseFailSmall } from '../../../utils/helpers/animations';
import { endgame } from '../../../utils/constants/constants';

export const TransitionLayer = styled.section`
  outline: 0;

  &:focus,
  &:hover {
    outline: 0;
  }
`;

export const RemoveLayer = styled.button`
  background: ${props => props.theme.game.buttonBg.default};
  border-radius: 20px;
  border: 0;
  cursor: pointer;
  height: ${props => props.theme.game.remove.size};
  padding: 0;
  position: absolute;
  right: -${props => props.theme.game.remove.size};
  top: 25px;
  width: ${props => props.theme.game.remove.size};

  &::after {
    background: ${props => props.theme.game.remove.bg};
    border-radius: 100%;
    content: '';
    height: ${props => props.theme.game.remove.size};
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: transform ${props => props.theme.transition.duration}
      ${props => props.theme.transition.cubicBez};
    width: ${props => props.theme.game.remove.size};
  }

  svg {
    fill: ${props => props.theme.game.buttonBg.icon};
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: fill ${props => props.theme.transition.duration}
      ${props => props.theme.transition.easeOut};
    transform-origin: 50%;
    z-index: 1;
  }

  &:focus,
  &:hover {
    outline: 0;
    &::after {
      transform: translate(-50%, -50%) scale(1);
    }
    svg {
      fill: ${props => props.theme.game.remove.bgHoverFill};
    }
  }

  &:focus::after {
    box-shadow: 0 0 0 2px solid;
  }
`;

export const RotateLayer = styled(RemoveLayer)`
  bottom: 25px;
  right: -25px;
  top: initial;
`;

export const MainGameBlock = styled.button`
  background: transparent;
  border: 0;
  border-radius: 5px;
  cursor: ${props => (props.layerNumber === 0 ? 'not-allowed' : 'pointer')};
  position: ${props => (props.layerNumber === 0 ? 'relative' : 'absolute')};
  top: ${props => (props.layerNumber === 0 ? 0 : props.theme.game.padding)};
  left: ${props => (props.layerNumber === 0 ? 0 : props.theme.game.padding)};
  transform: rotate(0deg);

  &:active,
  &:hover,
  &:focus {
    outline: 0;
  }

  &.win {
    animation: ${pulseSmall} ${endgame.pulseTime};
  }

  &.fail {
    animation: ${pulseFailSmall} ${endgame.pulseTime};
  }

  .fade-enter & {
    opacity: 0;
    transform: scale(1.5);
    transition: opacity ${props => props.theme.transition.fadeDuration}
        ${props => props.theme.transition.easeOut},
      transform ${props => props.theme.transition.fadeDuration}
        ${props => props.theme.transition.easeOut};
  }

  .fade-enter-active &,
  .fade-exit & {
    opacity: 1;
    transform: scale(1);
    transition: opacity ${props => props.theme.transition.fadeDuration}
        ${props => props.theme.transition.easeOut},
      transform ${props => props.theme.transition.fadeDuration}
        ${props => props.theme.transition.easeOut};
  }

  .fade-exit-active & {
    opacity: 0;
    transform: scale(1.5);
  }

  ${rotate90Transition};
`;
