import styled, { keyframes } from 'styled-components';

export const springIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: ${props => (props.raise ? 'translateY(-10px)' : 0)};
  }
`;

const IconMeanieStyled = styled.span`
  animation: ${springIn} 0.3s ${props => props.theme.transition.cubicBez}
    forwards;
  animation-delay: ${props => (props.delay ? `${props.delay}s` : 0)};
  background: ${props => props.theme.game.block.color.c4};
  border-radius: 100%;
  height: 30px;
  margin: 0 3px;
  outline: 0;
  opacity: 0;
  position: relative;
  transform: ${props => (props.raise ? 'translateY(-10px)' : 0)};
  width: 30px;

  &::before {
    background: ${props => props.theme.colors.copy};
    border-radius: 100%;
    content: '';
    height: 5px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 5px;
  }
`;

export default IconMeanieStyled;
