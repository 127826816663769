import uuidv1 from 'uuid/v1';
import { convertType } from '../constants/constants';

export const convertMDArrayToMDObj = (arr, convType) => {
  if (convType === convertType.normal) {
    return arr.map(block => ({
      bID: uuidv1(),
      row: block.map(row => [
        {
          rID: uuidv1(),
          col: row.map(type => ({
            cID: uuidv1(),
            type,
          })),
        },
      ]),
    }));
  }
  if (convType === convertType.shallow) {
    return {
      bID: uuidv1(),
      row: arr.map(row => [
        {
          rID: uuidv1(),
          col: row.map(type => ({
            cID: uuidv1(),
            type,
          })),
        },
      ]),
    };
  }
};
