export const loadDelay = 0;
export const siteURL = 'https://doopgame.com';
export const GAUA = 'UA-140429880-1';
export const transitionDuration = 300;
export const rotationDuration = 400;
export const fadeDuration = 400;
export const transDelay = 900;
export const doop = 'doop';
export const gameStages = {
  start: 1,
  meanies: 7,
};
export const optionsGroups = {
  minimum: 5,
  groupedInto: 3,
  noMore: 'No more layers to view',
  more: 'View more layers',
};
export const subtitle = 'A puzzle game';
export const strapline = 'Stack and swivel to succeed';
export const terms = {
  stack: 'STACK',
  goal: 'GOAL',
  options: 'LAYERS',
  option: 'LAYER',
  meanies: 'MEANIES',
  understood: 'OK, I get it.',
  tutorial: 'Lesson',
};
export const layerText = {
  locked: 'Current layer is locked',
  rotate: 'Rotate current layer',
  remove: 'Remove current layer',
};
export const convertType = {
  normal: 'normal',
  shallow: 'shallow',
};
export const endgame = {
  delay: 12,
  pulseTime: '1s infinite',
};
export const currentType = {
  overview: 'overview',
  overview2: 'overview2',
  target: 'target',
  target2: 'target2',
  layers: 'layers',
  layers2: 'layers2',
  layers3: 'layers3',
  layers4: 'layers4',
  layers5: 'layers5',
  layers6: 'layers6',
  options: 'options',
  options2: 'options2',
  meanies: 'meanies',
};
export const challengeTypes = {
  title: 'Challenge',
  starter: 'starter',
  easy: 'easy',
  easyish: 'easy-ish',
  medium: 'medium',
  mediumish: 'medium-ish',
  tricky: 'tricky',
  hard: 'hard',
  nuts: 'nuts',
  stupid: 'stupid',
};
export const helpTransitions = {
  add: 'adding-layer',
  rotate: 'rotating',
};
export const emptyGame = {
  starRating: 0,
  timeTaken: 9999,
  rotations: 9999,
  removals: 9999,
};
