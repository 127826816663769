import styled, { keyframes } from 'styled-components';

const tickAnim = keyframes`
  0%, 70% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(1.2);
  }
  75%, 95% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0);
  }
`;

const rotateAnim1 = keyframes`
  0% {
    opacity: 0;
    transform: scale(2);
  }
  10% {
    opacity: 1;
    transform: scale(1);
  }
  20% {
    transform: scale(1);
  }
  25% {
    transform: scale(1) rotate(90deg);
  }
  30% {
    transform: scale(1) rotate(0deg);
  }
  35% {
    transform: scale(1) rotate(-90deg);
  }
  40% {
    transform: scale(1) rotate(0deg);
  }
  50% {
    transform: scale(1) rotate(0deg);
  }
  60% {
    background: rgba(255,255,255,0);
    transform: rotate(0deg) scale(1);
  }
  70% {
    box-shadow: 0 0 0 0 rgba(255,255,255,0);
    transform:  scale(1);
  }
  75% {
    box-shadow: 0 0 0 10px rgba(142,121,247,0.8);
  }
  80%, 90% {
    box-shadow: 0 0 0 0 rgba(255,255,255,0);
  }
  95% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(0deg) scale(1);
  }
`;

const square = '70px';
const ball = '12px';

export const Col = styled.div`
  background: ${props =>
    props.c1
      ? props.theme.colors.bg
      : props.c2
      ? props.theme.game.block.color.c2
      : props.c3
      ? props.theme.game.block.color.c3
      : props.theme.game.block.color.c1};
  border-radius: 100%;
  margin: calc(${ball} / 4);
  width: ${ball};
  height: ${ball};
`;

export const Row = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const Layer1 = styled.div`
  align-items: center;
  animation: ${rotateAnim1} 8s linear infinite;
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  height: ${square};
  justify-content: center;
  padding: 0;
  position: absolute;
  width: ${square};
`;

export const Layer2 = styled(Layer1)`
  animation-delay: 0.4s;
`;

export const Layer3 = styled(Layer2)`
  animation-delay: 0.8s;
`;

const Example = styled.div`
  height: ${square};
  left: 50%;
  top: ${props => (props.help ? '22%' : '50%')};
  margin: 0;
  opacity: ${props => (props.small ? 1 : 0.1)};
  position: fixed;
  width: ${square};
  transition: transform ${props => props.theme.transition.duration}
    ${props => props.theme.transition.cubicBez};
  transform: translate(-50%, -50%) scale(0);

  &.example-enter-done {
    transform: translate(-50%, -50%)
      ${props => (props.small ? 'scale(0.72)' : 'scale(3)')};

    @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
      transform: translate(-50%, -50%)
        ${props => (props.small ? 'scale(0.72)' : 'scale(7)')};
    }
  }

  svg {
    animation: ${tickAnim} 8s linear infinite;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
  }
`;

export default Example;
