import { createBrowserHistory } from 'history';
import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import throttle from 'lodash/throttle';
import thunk from 'redux-thunk';
import createRootReducer from './reducers';

import initState from './initState';

import { loadState, saveState } from './localStorage';
import analyticsMiddleware from './middleware/analyticsMiddleware';

const persistedState = loadState();
export const history = createBrowserHistory();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const Store = createStore(
  createRootReducer(history), // root reducer with router state,
  {
    ...initState,
    ...persistedState,
  },
  composeEnhancers(
    applyMiddleware(routerMiddleware(history), analyticsMiddleware, thunk)
  )
);

// Save to localStoreage (throttle for performance)
Store.subscribe(
  throttle(() => {
    saveState({
      saved: Store.getState().saved,
    });
  }, 1000)
);

// Mock store
export const mockStore = createStore(
  createRootReducer(history), // root reducer with router state,
  initState,
  composeEnhancers(applyMiddleware(routerMiddleware(history), thunk))
);

export default Store;
