import styled from 'styled-components';

const IconLockStyled = styled.svg`
  cursor: not-allowed;
  fill: ${props => props.theme.icons.disabled};
  position: ${props => (props.main ? 'absolute' : 'relative')};
  right: ${props => (props.main ? '-22px' : 'initial')};
  bottom: ${props => (props.main ? '25px' : 'initial')};
`;

export default IconLockStyled;
