import styled from 'styled-components';
import OpenHelpStyled from '../OpenHelp/OpenHelp.styled';

const ReturnButtonStyled = styled(OpenHelpStyled)`
  top: 18px;
  left: 20px;
  right: inherit;

  svg {
    width: inherit;
  }
`;

export default ReturnButtonStyled;
